/* Global selct box Style*/
@mixin filter-text-before {
  position: absolute;
  left: 9px;
  top: -6px;
  text-transform: initial; 
  font-size: 12px;
  line-height: 14px;
  background: #fff;
  color: #27364B;
  padding: 0 3px;
  z-index: 99;
} 

.form-group{
  position: relative;
  .StaticName{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--theme-text-color-03);
    position: absolute;
    z-index: 9;
    top: 14px;
    left: 12px;
  }
  .AddMoreFltr{
    text-decoration: none;
    border-radius: 4px;
    padding: 0 16px;
    gap: 8px; 
    border: var(--ng-select-border);
    &:hover{
      color: var(--text-color-defolt); 
      border-color: var(--text-color-defolt);
    }
  }
} 


ng-select{
  background: var(--ng-select-background); 
  border: none;
  box-sizing:  var(--ng-select-box-sizing);
  border-radius: 4px;
  min-width: auto;  
  &.ng-select-large{
    .ng-dropdown-panel{
      min-width: 402px;   
    }
    &.ng-select-md{
      .ng-dropdown-panel{
         min-width: 255px;
      }
    }
    &.ng-select-width-100{
      .ng-dropdown-panel{
        @media screen and (max-width: 992px) {
          min-width: 100%;   
          width: 100%;
        }
      }
    }
  }
  &.td-in-drp{
    .ng-select-container{
      padding: 0 30px 0 0 !important; 
      border: none;
      .ng-value-icon{
        display: none;
      }
    }
  }
  &.PlaceholderLft{
    .ng-select-container{  
      .ng-value-container{
      .ng-placeholder{ 
        @media screen and (max-width: 767px) {
          text-align: left; 
        }
      }
    }  
  } 
  .ng-dropdown-panel{
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  }
  &.textlimitdots{
    .ng-select-container{
      span{
        max-width: 130px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @media screen and (min-width: 768px) {
          max-width: inherit;
        }
        @media screen and (max-width: 767px) {
          max-width: 80px;
        }
      }
    }
  }
  height: 48px;
  outline: var(--ng-select-outline);
  box-shadow: var(--ng-select-box-shadow);
  font-style: var(--ng-select-font-style);
  font-weight: var(--ng-select-font-weight);  
  color: var(--ng-select-color);
  text-transform: var(--ng-select-text-transform);
  padding: 0px; 
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: var(--ng-select-background-image); 
  background-repeat: var(--ng-select-background-repeat);
  background-size: auto;
  background-position: right 12px top 15px;
  display: var(--ng-select-display);
  position: var(--ng-select-position);
  .ng-select-container{
    border:  var(--ng-select-border);
    border-radius: 4px;
    .ng-value-container{
      .ng-placeholder{
        position: static !important;
        font-weight: 700;
        height: 47px;
        line-height: 47px;
        @media screen and (max-width: 992px) {
          width: 100%;
          text-align: center; 
        }
      }
    }
    &.ng-has-value{
      border-color: var(--text-color-defolt);
      .ng-value-container{
        .ng-placeholder{
          display: none;
        }
      }
    } 
    padding: 0px 38px 0px 8px !important; 
    height: 100% !important;
    cursor: pointer !important;
    .ng-clear-wrapper{
      display: none;
    }
    .ng-value{
      width: 100%;
      .ng-value-label{
        height: 100%; 
        display: block;
        cursor: pointer; 
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 48px; 
        color: var(--theme-text-color-03);
        
      }
    } 
    .ng-input{
      input{
        padding: 8px 8px !important; 
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        color: transparent;
      }
    } 
  }
  &.ng-select-opened{
      /*border: var(--ng-select-opened-border);*/
      background-image:var(--ng-select-opened-background-image);  
      .ng-input{
        input{ 
          color: var(--theme-text-color-03) !important;
        }
      }  
      .ng-select-container {
        z-index: 9 !important;
    }
  } 
 
   
  .ng-dropdown-panel-items{ 
    background: #FFFFFF !important;
    border: 1px solid #CBD4DB !important;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
    border-radius: 4px; 
    max-height: 200px !important;
    @media screen and (max-width: 992px) {
      max-height: 180px !important;
      }
  }
  &.port_drpDwon{
    .ng-dropdown-panel-items{ 
      width: 206px;
    }
  }
  &.ConatctFtr{
    .ng-select-container {
      .ng-value {
        .ng-value-label{
          font-weight: 700;
          color: var(--theme-text-color-01) !important;
        }
      }
    }
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        padding-right: 0;
        .ng-option {
          padding-left: 0;
          padding-right: 0;
          .ng-option-label{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline;
          }
      }
      }
    }
  }
  .ng-option{ 
    background-color: var(--white-color); 
    padding: 0px 0px;
    height: 48px; 
    font-size: 16px;
    line-height: 48px; 
    letter-spacing: -0.15px; 
    color: var(--theme-text-color-03);
    border-bottom: 0.5px solid #E8ECEE;
    font-style: normal;
    font-weight: normal;
    &.ng-option-disabled{
      padding: 0px 12px;
    }
    .ng-option-label{
      background-color: var(--white-color); 
      display: flex;
      width: 100%;
      height: 100%;
      padding: 0px 12px;
    }
    &:hover{
      .ng-option-label{
        background-color: #F6F8FC;
        color: var(--theme-text-color-03);
        display: flex;
        width: 100%;
        height: 100%;
      }

    }
    &.ng-option-selected{
      display: flex !important;
      align-items: center;
      justify-content: center;
      .ng-option-label{ 
        color: var(--theme-text-color-03);  
        font-weight: 700;
      }
    }
  }
  &.TextSlectGroup{ 
    .ng-select-container{ 
      padding: 0 38px 0px 12px !important;
      .ng-input{
        input{
          max-width: 75px;
          overflow: hidden;
        }
      }
    }   
     &._Delay{
      &::before{
        @include filter-text-before;
        content: "Delay";
       } 
     }
     &._ETAChange{
      &::before{
        @include filter-text-before;
        content: "ETA change";
       } 
       .ng-select-container{
        min-width: 124px; 
      }
     }
     &._Vessel{ 
       &::before{
        @include filter-text-before;
        content: "Vessel";
       } 
     }
     &._assignedSurvey{ 
      min-width: 116px;
      &::before{
       @include filter-text-before;
       content: "Assigned survey";
      } 
      &._assignedSurveydrp{
        .ng-select-container {
          .ng-value {
            .ng-value-label{
              font-weight: 700 !important;
            }
          }
        } 
      }
    }
    &._CreatedBy{
      min-width: 84px; 
      &::before{
       @include filter-text-before;
       content: "Created by";
      } 
    }
    
     &._company{ 
      &::before{
       @include filter-text-before;
       content: "Company";
      } 
      .ng-select-container{
        min-width: 76px;
      }
    }
    &._Voyageno{ 
      &::before{
       @include filter-text-before; 
       content: "Voyage no.";
      }
      .ng-select-container{
        min-width: 84px;
      } 
    }
     &._PortFilter{ 
      &::before{
        @include filter-text-before;
        content: "Port"; 
      } 
    }
    &._id{ 
      &::before{
       @include filter-text-before;
       content: "ID";
      } 
    }
    &._type{ 
      &::before{
       @include filter-text-before;
       content: "Type";
      } 
    }
    &._status{ 
      &::before{
       @include filter-text-before;
       content: "Status";
      } 
    }

    &._Birth{ 
      &::before{
        @include filter-text-before;
        content: "Berth"; 
      } 
    }

    &._Alert{ 
      &::before{
        @include filter-text-before;
        content: "Alert"; 
      } 
    }
    &._Cargo{ 
      &::before{
        @include filter-text-before;
        content: "Cargo"; 
      } 
    }
    &._Trade{ 
      &::before{
        @include filter-text-before;
        content: "Trade"; 
      } 
    }

    &._Months{ 
      &::before{
        @include filter-text-before;
        content: "Month";
      } 
    }  
    
    &._Quarters{ 
      &::before{
        @include filter-text-before;
        content: "Quarter";
      } 
    } 
    
    &._Years{ 
      &::before{
        @include filter-text-before;
        content: "Year"; 
      } 
    }  
     
    &._TotalCO2{ 
      &::before{
        @include filter-text-before;
        content: "Total CO2 emissions";
      }
     .ng-select-container{
       padding-left:171px !important;
       .ng-input{
         input{
           padding-left:171px !important;
         }
       }
     }
    }
     &._Year{
      .ng-select-container{
        padding-left:55px !important;
        .ng-input{
          input{
            padding-left:55px !important;
          }
        }
      }
     }
     &._Traderoute{
      .ng-select-container{
        padding-left:104px !important;
        .ng-input{
          input{
            padding-left:104px !important;
          }
        }
      }
     }
     &._Port{ 
      .ng-select-container{
        padding-left:52px !important;
        .ng-input{
          input{
            padding-left:52px !important;
          }
        }
      }
     }  
     &._Port_Etb{ 
      &::before{
        @include filter-text-before;
        content: "Port";
       }
      .ng-select-container{
         min-width: 76px;
      } 
     } 
     &._Load{ 
      &::before{
        @include filter-text-before;
        content: "Load port";
       }
      .ng-select-container{
         min-width: 76px;
      } 
     }     
     &._Discharge{
      &::before{
        @include filter-text-before;
        content: "Discharge port";
       }
      .ng-select-container{
        min-width: 104px; 
      } 
     } 
      &._Company{
      &::before{
        @include filter-text-before;
        content: "Company:";
       }
      .ng-select-container{
        padding-left:88px !important;
        .ng-input{
          input{
            padding-left:88px !important;
          }
        }
      }
      &._Company2{
        .ng-select-container{
          padding-left:134px !important;
          .ng-input{
            input{
              padding-left:134px !important;
            }
          }
        }
      }
     }
     &._Fleet{
      &::before{
        @include filter-text-before;
        content: "Fleet";
       }
      .ng-select-container{ 
        .ng-placeholder{
          @media screen and (max-width: 992px) {
             line-height: 48px;
             text-align: left;
          }
        } 
      }
      &._Fleet2{
        .ng-select-container{
          padding-left:134px !important;
          .ng-input{
            input{
              padding-left:134px !important;
            }
          }
        }
      }
     }
     &._Charterer{
      &::before{
        @include filter-text-before;
        content: "Charterer";
       }
      .ng-select-container{
        min-width: 76px; 
      } 
     }  

     &._Timezone{
      .ng-value-label{
        font-weight: 700;
        white-space: nowrap;
        max-width: 348px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &::before{
        @include filter-text-before;
        content: "Time zone";
       } 
     }  

     &._Tradelane{
      &::before{
        @include filter-text-before;
        content: "Tradelane";
       }
      .ng-select-container{
        min-width: 76px; 
      }
     } 
     &.NoTextTransform{
      text-transform: inherit !important;
     }

     &._Broker{
      &::before{
        @include filter-text-before;
        content: "Broker";
       }
      .ng-select-container{
        padding-left:70px; 
      }
     } 

     &.w288{
      .ng-select-container{
        .ng-value-container{
          span{
            white-space: nowrap;
            max-width: 234px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      ng-dropdown-panel{
        width: 288px !important;
        .ng-dropdown-panel-items{
          .ng-option{
            .checkboxHld{
              .OptionTxt{
                white-space: nowrap;
                max-width: 234px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline;
              }
            }
          }
        }
      }
     }
     &._ETAVariance{
      &::before{
        @include filter-text-before;
        content: "ETA Variance"; 
       }
      .ng-select-container{
        min-width: 100px;
        div, span{
          font-weight: 700;
        }
        .ng-input{
          input{
            display: none;
          }
        }
      }
      ng-dropdown-panel{
        width: auto;
        .ng-dropdown-panel-items {
          .ng-option { 
            padding-right: 14px;
        }
        }
      }
     } 
     
     &._Bookingid{
      &::before{
        @include filter-text-before;
        content: "Booking ID";
       }
      .ng-select-container{
        min-width: 83px; 
      }
     }
     
     &._Organization{
      &::before{
        @include filter-text-before;
        content: "Organisation";
       }
      .ng-select-container{
        min-width: 92px; 
      }
     }

     &._Moduleaccess{
      &::before{
        @include filter-text-before;
        content: "Module access";
       }
      .ng-select-container{
        min-width: 108px; 
      }
     }

     &._Modules{
      &::before{
        @include filter-text-before;
        content: "Modules";
       }
      .ng-select-container{
        min-width: 108px; 
      }
     }
     &.user-mng-drop{
      .ng-select-container {
        &.ng-has-value{
          border-color: var(--neutral-9) !important;
        }
      }
      ng-dropdown-panel{
        width: 360px;
        @media screen and (max-width: 767px) { 
          width: 100%;  
        }
        .ng-dropdown-header {
          padding: 12px 8px;
        }
        .ng-dropdown-panel-items{
          .ng-option{
            padding-left: 16px;
            padding-right: 16px;
            snl-radio-button{
              .container__radio{
                align-items: center;
                input{
                  margin-top: 2px;
                  cursor: pointer;
                }
              }
            }
            span{
              color: #27364B;
            }
            &.ng-option-selected{
              font-weight: 700;
            }
            .option-disabled{
              opacity: .5;
            }
            .container__checkbox{
              .OptionTxt{
                font-size: 16px !important;
                color: var(--font-color-primary);
              }
            }
          }
        }
        .ng-dropdown-footer{
          .StoltBtnIconLarge{
            font-size: 16px;
          }
          @media screen and (max-width: 992px) { 
           position: static; 
          }
        }
      }
     }

     &._Statusrole{
      &::before{
        @include filter-text-before;
        content: "Status";
       }
      .ng-select-container{
        min-width: 76px; 
      } 
     }
     &._role{
      &::before{
        @include filter-text-before;
        content: "Role";
       }
      .ng-select-container{
        min-width: 76px; 
      } 
     }
     &._CustomerCategory{
      &::before{
        @include filter-text-before;
        content: "Customer’s category";
       }
      .ng-select-container{
        min-width: 76px; 
        &.ng-has-value{
          .ng-placeholder{
            display: block;
          }
        }
      } 
      &.ng-toggle{
        .ng-placeholder{
          display: none !important;
        }
      }
     }
    &.select-space{
      .ng-select-container{ 
        padding: 0 38px 0px 16px !important;
       }
    }
  }
   
  &.ng-select-fade{      
    background-image: var(--ng-select-background-image-2) !important; 
    background-size: 12px;
    background-position: right 17px top 21px;  
    .ng-select-container{
      padding-left: 16px !important;
      .ng-input {
        input{
          padding-left: 16px !important;
        }
      }
      .ng-value {
          span{
              &.ng-value-label{
                color: var(--front-color-defolt04);
              }
          }
      }
  }
  }
  .ng-dropdown-panel{
    background: #FFFFFF;
    border: 1px solid #E2E7EB;
    box-shadow: 0px 10px 15px rgb(0 0 0 / 6%);
    border-radius: 4px;
    width: 366px;
    padding: 0; 
    margin-top: 4px; 
    .checkboxHld{
      .containerCheckbx{
        padding: 0 !important; 
      }
      .OptionTxt{
        padding-left: 31px;
        position: relative;
        &.SpaceLeftOpt{
          padding-left: 36px;
        }
      }
    }

    .ng-dropdown-header{
      padding: 12px;
      input{
        width: 100%; 
        padding: 12px 16px;  
        height: 48px;
        border: 1px solid #CBD4E1;
        border-radius: 4px;
        background-image: var(--SearchIconBG5) !important; 
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: right 14px top 15px;
        outline: none;
        &::placeholder{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px; 
          color: #8392A8;
        }
      }
    }
    .ng-dropdown-footer{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px; 
      @media screen and (max-width: 992px) {
          position: fixed;
          width: 100%;
          bottom: 0; 
          gap: 16px;
          padding: 16px;
          background-color: #fff;
      }
      button{
        min-width: 47%; 
      }
       
    }
    .ng-dropdown-panel-items{
      border: none !important;
      box-shadow: none;
      padding: 0 6px;
      padding-left: 0;
      .SelectAllOpt{
        background-color: var(--white-color);
        padding: 0px 0px;
        height: 48px;
        font-size: 16px;
        line-height: 48px;
        letter-spacing: -0.15px;
        color: var(--theme-text-color-03);
        border-bottom: 0.5px solid #E8ECEE;
        font-style: normal; 
        padding-left: 14px;
        font-weight: 700;
        cursor: pointer;
      }
       
      .ng-option{
        padding-left: 14px;
        padding-right: 7px;
        span{
          width: 100%;
          display: flex;
          flex-direction: column; 
          &.SelectedCheck{
            background-image: var(--tick-check) !important; 
            font-weight: 700; 
            padding-left: 34px; 
            background-repeat: no-repeat;
            background-position: center left;
          }
          &.checkmark{
            width: 20px;
          }
        }
        &.ng-option-selected {
          display: flex !important;
          align-items: flex-start;
          justify-content: flex-start;
        }
        &.ng-option-disabled{
          margin-bottom: 12px
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
  &.OpenDropdownRight{
    .ng-dropdown-panel{
      right: 0;
    } 
  }
  &.OpenDropdownCenter{
    .ng-dropdown-panel{
      left: -106px;
    } 
  }
  &.OpenDropdownTop{
    .ng-dropdown-panel{
      margin-top: 0;
      bottom: 54px;
      box-shadow: 0px -5px 15px rgba(30, 33, 46, 0.06); 
    }
      &.SetingFltr{
        .ng-dropdown-panel {
          @media screen and (max-width: 992px) {
             padding-bottom: 12px;
          }
      }
    }
  }

  &.MobileOpenDropdownTop{
    @media screen and (max-width: 992px) {
      .ng-dropdown-panel{
          margin-top: 0;
          bottom: 62px;
          box-shadow: 0px -5px 15px rgba(30, 33, 46, 0.06);  
        } 
      &.HeightController {
        .ng-dropdown-panel-items {
          max-height: 240px !important;
        }
      }
    } 
  }

  &.OpenDropdownLeft-mobile{ 
      .ng-dropdown-panel{ 
        @media screen and (max-width: 992px) {
          left: 0; 
        }
      }  
  }
  &.drop-mobile-width{
    @media screen and (max-width: 992px) {
      .ng-dropdown-panel {  
          width: 270px; 
          .ng-dropdown-panel-items{
            .ng-option{
              span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; 
                display: inline;
              }
            }
          }
      }
      &.m_left0{
        .ng-dropdown-panel {  
            right: inherit;
        }
      }
      &.m_centered{
        .ng-dropdown-panel {  
          left: -100px;
        }
      }
    }
  } 
  &.no-scroll-drpdwn{
    .ng-dropdown-panel-items{
      margin: 0;
      padding: 0;
    }
  }
  &._Fleet{
    ng-dropdown-panel{
      width: 250px; 
      .ng-dropdown-panel-items{
          &.scroll-host{
              max-height: 200px !important;
              margin-bottom: 4px;
          }
      }
  }
  }

  &.ng-select-2{
    background-color: var(--Bg-color-defolt05) !important;
    background-image: var(--ng-select-background-image-3);  
    background-size: 16px;
    background-position: right 12px top 20px;
    height: 56px !important;
    letter-spacing: 0.2px;
    line-height: 24px !important;
    padding: 12px !important;
    border: none;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--theme-light-grey-color) !important;
    &.is-invalid{
      border-bottom: 1px solid #CF0027 !important;
    }
    &::placeholder{
      color: var(--front-color-defolt06) !important;
    } 
    &.ng-select-opened{
      background-color: #FCFAF6 !important;
      background-image: var(--ng-select-background-image-3-active); 
      border-bottom: 2px solid var(--primrose-yellow-color) !important;
      &.FloatingLabel{
        &:after{
          color: var(--primrose-yellow-color) !important;
        }
      }
    }
    
    &.InnerTxtCntlr{
      .ng-select-container{ 
        height: 44px !important;
        border: none;
        .ng-value-container{
        .ng-placeholder, .ng-value{
          font-weight: 400;
          height: 37px;
          line-height: 37px;
          letter-spacing: 0.2px;
          color: var(--theme-text-color-01); 
          @media screen and (max-width: 992px) {
            text-align: left;
          }
        }
        } 
        .ng-value{
        .ng-value-label{
          font-weight: 400;
          height: 37px;
          line-height: 37px;
          letter-spacing: 0.2px;
          color: var(--theme-text-color-01); 
        }
        } 
      }
      &.pl-0-select-container{
        .ng-select-container{ 
          padding-left: 0 !important;
        }
      }
    }
    &.GreyTextSelect{
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder { 
            color: var(--front-color-defolt06);
          }
        } 
      }
    }
    .ng-dropdown-panel {
      left: 0;
      width: 362px;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }
  &.OtherInputCstomze{
    .ng-select-container {
      .ng-value-container {
        .ng-input { 
          display: none;
        }
      } 
    }
    .ng-dropdown-footer{
      background: transparent;
      padding: 0 6px 12px 6px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 8px;
      .OtherOpt{
        width: 100%; 
        padding: 0 0 0 16px; 
        display: flex;
        align-items: center;
        column-gap: 8px;
        .ChevBack{
          cursor: pointer;
        }
      }
      .OtherInputF{ 
        width: 100%;
        padding-bottom: 12px;
        .form-floating{
          label{ 
            font-weight: 400;
            font-size: 16px !important;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            color: var(--front-color-defolt06) !important;
            margin: 19px 12px 12px 12px !important;
            text-transform: none;
          } 
        }
        .form-control:focus~label, .form-control:not(:placeholder-shown)~label{
          opacity: 1 !important;
          transform: inherit !important;
          background: transparent !important;
          padding: 0 12px !important;
          margin: 0 !important;  
          color: var(--theme-text-color-02) !important; 
          font-size: 14px !important; 
          @media screen and (max-width: 992px) {
            margin-top: -1px !important;
            font-size: 13px !important; 
          }
        }

      }
      @media screen and (max-width: 992px) {
        position: static;
      }
      .form-control-stolt{
        &.fc-stolt2{
          background: #FCFAF6 !important;
          border: 2px solid var(--primrose-yellow-color) !important;
          border-radius: 8px !important;
          text-transform: capitalize;
          height: 56px !important;
          line-height: 56px !important;
          padding: 19px 12px 12px 12px !important;
          &::placeholder{
            color: transparent !important;
          }
        } 
      }
    }
    &.disbeldNoItem{
      .ng-dropdown-panel-items{
        //display: none;
      }
      .ng-option{
        &.ng-option-disabled{
          display: none;
        }
      }
    }
  }
  &::placeholder{
    color: var(--front-color-defolt04) !important;
  }  
  &.m-footer-not-fixed{
    .ng-dropdown-panel {
      .ng-dropdown-footer {
        @media screen and (max-width: 992px) {
          position: static; 
          button {
            min-width: 108px;
          }
        }
      }
    }
  }
} 

.MobileBtnFtr{
  .ng-dropdown-panel {
    .ng-dropdown-footer {
      background: none;
      padding: 16px; 
    }
  }
}
.SingleSelect{
  background: var(--ng-select-background); 
  border:  var(--ng-select-border);
  box-sizing:  var(--ng-select-box-sizing);
  border-radius: 6px;
  min-width: 184px;  
  height: 48px;
  outline: var(--ng-select-outline);
  box-shadow: var(--ng-select-box-shadow); 
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--theme-text-color-03);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  padding: 0px 12px;
}
.CommonLastActive{
  &._Lastactive{
    width: 102px; 
    &:before{
      position: absolute;
      left: 9px;
      top: -6px;
      text-transform: initial;
      font-size: 12px;
      line-height: 14px;
      background: #fff;
      color: #27364B;
      padding: 0 3px;
      z-index: 99;
      content: "Last signed in";
    }
    &.date-selected{
      width: 248px; 
      input{
        border-color: var(--text-color-defolt);
      }
    }
    input{
      max-width: 100%;
      width: 100%;
      background-color: transparent;
      @media screen and (max-width: 992px) {
        display: none;
      }
    }  
   }
  .SingleSelect{
    padding: 0 38px 0px 12px; 
    font-weight: 700;
    color: transparent;
    text-shadow: 0 0 0 #495057;
    min-width: 102px;  
    cursor: pointer;
    &.active{   
      background-image: var(--ng-select-opened-background-image);
    }
    &::placeholder{
      color: #495057;
      font-weight: 700;
    } 
    background-image: var(--ng-select-background-image);
    background-repeat: var(--ng-select-background-repeat);
    background-size: auto;
    background-position: right 12px top 15px;
    background-color: transparent;
  }
  ngx-daterangepicker-material{
    .md-drppicker{
      .ranges {
        ul {
          li {
            button{
              &.active {
                background-color: transparent;
                color: transparent;
              }
            }
          }
        }
      }
      &.drops-down-auto{
        &.double{
          &.show-ranges{ 
              top: 64px !important;
              left: -380px !important;
              width: 773px;
              right: 0 !important;
              padding: 0;
              box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
              border-radius: 4px;
              @media screen and (max-width: 992px) {
                transform: scale(1);
                transition: all .1s ease-in-out;
                transform-origin: 0 0;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                user-select: none;
                border-radius: 0;
                box-shadow: none;
                max-height: 600px;
                @media screen and (max-width: 380px) {
                  max-height: 430px;
                  padding-bottom: 0px;
                } 
                width: 100%;
                overflow-y: auto;
                margin-top: 10px;
                padding-bottom: 80px;
                left: 0 !important;
              }
              .ranges{
                width: 142px;
                border-right: 1px solid #DBE3ED;
                @media screen and (max-width: 992px) {
                  width: 100%;
                  border: none;
                }
                ul{
                  @media screen and (max-width: 992px) {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 1px solid #F1F4F9; 
                  }
                  li{
                    border-bottom: 1px solid #F1F4F9; 
                    @media screen and (max-width: 992px) {
                      padding: 0;
                      width: 20%;
                      border: none;
                    }
                    @media screen and (max-width: 767px) { 
                      width: 50%;
                    }
                    &:hover{
                      background-color: #E5F4FA; 
                      @media screen and (max-width: 992px) {
                        background-color: transparent; 
                        color: #27364B;
                      }
                      button{
                        color: #008ECF !important;
                      }
                    }
                    button{
                      height: 48px;
                      padding: 0 16px;
                      font-size: 16px;
                      line-height: 24px;
                      color: #27364B; 
                      @media screen and (max-width: 992px) {
                        background-image: var(--radiobutton-icon-circle) !important;
                        padding-left: 50px;
                        background-repeat: no-repeat;
                        background-position: center left 16px;
                      }
                      &.active{
                        background-color: #E5F4FA; 
                        color: #008ECF !important;
                        @media screen and (max-width: 992px) {
                          background-color: transparent;
                          color: #27364B !important;
                          font-weight: 700;
                          background-image: var(--radiobutton-icon) !important;
                        }
                      }
                    }
                  }
                }
              }
              .calendar{
                border-right: 1px solid #DBE3ED;
                @media screen and (max-width: 992px) {
                  display: block;
                  border: none;
                  width: 50%;
                  max-width: inherit;
                }
                @media screen and (max-width: 767px) {
                  width: 100%; 
                }
                &.right{
                  border-right:none; 
                }
                max-width: 315px;
                margin: 0 !important; 
                .calendar-table{
                  padding: 16px;
                  min-height: 292px;
                  table{
                    thead{
                      tr{
                        &.week-days{
                          th{
                            padding: 8px 0 12px 0;
                          }
                        }
                        th{
                          &.month{
                            padding: 4px 0;
                          }
                          &.prev, &.next{
                            width: 26px;
                            height: 26px;
                            position: relative;
                            left: 6px;
                          }
                          &.next{
                            left: inherit;
                            right: -6px;
                          }
                          color: #64748B;
                          font-size: 12px;
                          line-height: 16px;
                          height: auto;
                          min-height: auto;
                          min-width: auto;
                          width: auto;
                        }
                      }
                    }
                    tbody{
                      tr{
                        td{
                          color: #27364B;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 24px;
                          height: 32px;
                          min-height: 32px;
                          @media screen and (max-width: 992px) {
                            height: 48px;
                            min-height: 48px; 
                          }
                          min-width: 40px;
                          width: 40px;
                          opacity: 1 !important;
                          border: none;
                          &.active{
                            background-color: #008ECF;
                            font-weight: 700;
                            color: #fff;
                            border-radius: 4px !important;
                          }
                          &.available{
                            border-radius: 0; 
                            &.off{
                            background-color: #fff; 
                            color: #999;
                            font-weight: 400;
                            }
                          }
                          &.today{
                            font-weight: 700;
                          }
                          &.in-range {
                            background-color: #E5F4FA;
                            border-color: transparent;
                            color: #008ECF !important;
                            border-radius: 0 !important;
                            border: none;
                        }
                        &.end-date{
                          &.in-range{
                            background-color: #008ECF;
                            color: #fff !important;
                            border-radius: 4px !important;
                          }
                        }
                        }
                      }
                    }
                  }
                }
              }
            }
          
        }
      } 
    }
  }

}
/* Global selct box Style End*/


/* Global Form Fields */
@mixin floating-field-common {
  background: var(--Bg-color-defolt05) !important;
  height: 56px !important;
  letter-spacing: 0.2px;
  line-height: 24px !important;
  padding: 19px 12px 12px 12px !important;
  border-radius: 8px 8px 0px 0px !important;
  border: none !important;
  border-bottom: 1px solid var(--theme-light-grey-color) !important;
}

.form-control{ 
    &:focus{
      outline: none !important;
      box-shadow: none !important;  
    }
    &.is-invalid{
      background-image: none !important;
    }
}

.form-control-stolt{
  height: 48px;
  font-size: 16px !important;
  line-height: 28px !important;
  padding: 10px 16px !important; 
  background: var(--Bg-color-defolt04) !important;
  border-radius: 0px !important;
  outline: none;
  border: none !important;
  box-shadow: none !important;
  &.fc-stolt2{
    @include floating-field-common;
    &.is-invalid, &.input-error{
      border-bottom: 1px solid #CF0027 !important;
    }
    &::placeholder{
      color: var(--front-color-defolt06) !important;
    } 
  }
  &::placeholder{
    color: var(--front-color-defolt04) !important;
  } 
}

.form-floating{
  .floating-control-no-bg{
    border: 1px solid #a5a1a1  !important;
    border-radius: 4px !important;
    background: transparent;
    height: 48px !important; 
    min-height: 48px !important;
    line-height: 28px !important;
    padding: 10px 16px !important; 
    &:focus{
      border-color: var(--text-color-defolt) !important;
    }
  }
  label{
    padding: 0 !important;
    margin: 12px 16px !important; 
    color: var(--front-color-defolt04) !important;
    height: auto !important;
    font-size: 14px !important;
    transition: 0.2s !important;
  } 
  &.form-floating2{
    .form-control-stolt{
      &.form-control-small{
        padding: 13px 12px !important;
        height: 48px !important;
        min-height: 48px !important;
      }
      @include floating-field-common;
      &:focus{
        border-bottom: 2px solid var(--primrose-yellow-color) !important;
        &.InnerInpt{
          border-bottom: 1.5px solid var(--text-color-defolt) !important; 
          opacity: 1;
          &.input-error {
            border-bottom: 1px solid #CF0027 !important;
          }
        }
      }
      &.is-invalid, &.input-error {
        border-bottom: 1px solid #CF0027 !important;
      }
      &::placeholder{
        color: transparent !important;
      }
    }
    label{
        color: var(--front-color-defolt06) !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.2px;
        margin: 16px 12px !important;
        z-index: 999;
    }
    
    .form-control:focus~label, .form-control:not(:placeholder-shown)~label{
      opacity: 1 !important;
      transform: inherit !important;
      background: transparent !important;
      padding: 0 12px !important;
      margin: 0 !important;  
      color: var(--theme-text-color-02) !important; 
      font-size: 14px !important; 
      &:after{display:none;}
      @media screen and (max-width: 992px) {
        margin-top: -1px !important;
        font-size: 13px !important; 
      }
    }
  } 
}
  
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label{
  opacity: 1 !important;
  transform: scale(0.75) translateY(-28px) translateX(0) !important;
  background: var(--white-color);
  padding: 0 6px !important;
  margin: 13px 10px !important; 
  height: auto; 
  width: auto;
}
 
/* Global Form Fields End */


/* custom choose file button */
.file__choose{ 
  label {
    cursor: pointer;
    border: 1px solid var(--text-color-defolt);
    color: var(--text-color-defolt);
    background: var(--background--white);
    padding: 10px 16px; 
    border-radius: 4px; 
 }
 
 #upload-file {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 0;
 } 
}



/* Global Checkbox Style*/
.checkboxHld {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;  
  &.all_checkmng{
    .containerCheckbx {
      .checkmark{
        border: 2px solid var(--text-color-defolt); 
        background-color: var(--text-color-defolt);
        &:after{
          content: "";
          position: absolute;
          left: 3.5px;
          top: 7.5px;
          width: 10px; 
          height: 2px;
          background: #fff;
          display: block;
          border-radius: 4px;
          padding: 0;
          border: none;
          transform: inherit;
        }
      }
      input:checked ~ .checkmark {
        background: var(--text-color-defolt);
        border-color: var(--text-color-defolt); 
        
      }
    }
  }
  .containerCheckbx {
    display: block;
    position: relative;
    top:5px;
    padding: 0px 0px 0px 31px; 
    cursor: pointer; 
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.TableCheckBx{
      padding-left: 0;
    }
    &.tp15{
      top:14px;
    }
    &.tp02{
      top:2px;
    }
    &.tp0{
      top: 0;
    }
     


    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

    }

    input:checked~.checkmark {
      background-color: var(--text-color-defolt);
      border: 2px solid var(--text-color-defolt);
      &.checkmark2{
        border: 1px solid var(--text-color-defolt); 
        &.bder2{
          border: 2px solid var(--text-color-defolt); 
        } 
      }

    }

    input:checked~.checkmark:after {
      display: block;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0; 
      width: 20px;
      height: 20px; 
      background: #FFFFFF;
      border: 1.4px solid #94A3B8;
      box-sizing: border-box;
      border-radius: 4px;
      &.SkeletonCheckBx{
        background: #D9D9D9;
      }
      &.tp02{
        top:2px;
      }

      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid #FFFFFF;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  &.RadioBtnCircle{
    &.justify_content_center{
      justify-content: center !important;
    }
    &.RadioByBG{
      .containerCheckbx{
        input:checked ~ {
          .checkmark{
            &:after{
              position: static;
              background-color: transparent !important;
              margin: 0 !important;
              width: 20px !important;
              height: 20px !important;
              background-image: var(--radiobutton-icon-active) !important;
            }
          }
        }
      }
      .checkmark{
        background-image: var(--radiobutton-icon-circle) !important; 
        background-repeat: no-repeat; 
        background-position: center;
        border: none !important;
        background-size: 20px;  
      }
    }
    .containerCheckbx {
      top: 1px;
      &.tp13{
        top: 13px;
      }
      &.N-tp10{
        top:-10px; 
      }
      .checkmark{
        border-radius: 50%; 
        background-color: var(--white-color) !important;
        border: 2px solid #718096;
        &::after{
          width: 9px;
          height: 9px;
          background: var(--text-color-defolt);
          border-width: inherit;
          transform: inherit;
          border-radius: 50%;
          top: 3px;
          margin: 0.7px 0 0 -2.5px;
          border: none;
        }
        &.checkmark2{
          border: 1px solid #CBD4E1;
          &.bder2{
            border: 2px solid #718096; 
            &::after{
              width: 10px;
              height: 10px; 
          }
          }
          
          &::after{
            width: 12px;
            height: 12px;
            margin: 0 0 0 -3px;
        }
        }
      }
    }
  }
} 
/* Global Checkbox Style End*/


/* Global switch button Style*/
.stolt-switch-btn{
  display: flex;
  justify-content: center;
  .switch {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 16px;
    &.switch-lg{
      width: 54px;
      height: 24px;
      @media screen and (max-width: 992px) { 
        width: 64px;
        height: 32px;  
      }
      .slider{
        &::before{
          width: 16px;
          height: 16px; 
          @media screen and (max-width: 992px) { 
            width: 26px;
            height: 26px;  
          }
        }
        &::after{
          position: absolute;
          content: "OFF";
          color: #475569;
          text-transform: uppercase;
          font-weight: 700;
          left: 24px;
          font-size: 12px;
          line-height: 16px;
          top: 4px;
          @media screen and (max-width: 992px) { 
            top: 8px;
            left: 33px;
          }
        }
      }
      input{
        &:checked + .slider{
          &:before { 
            width: 16px;
            height: 16px; 
            @media screen and (max-width: 992px) { 
              width: 26px;
              height: 26px;  
            }
            left: 5px;
            -webkit-transform: translate(28px,-50%);
            -ms-transform: translate(28px,-50%);  
            transform: translate(28px,-50%); 
          }
          &:after{ 
            content: "On";
            color: #fff; 
            left: 8px; 
          }
        }
      }
    }
    input { 
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .slider {
        background-color: #008ECF;
        border: 0.75px solid #008ECF;
      } 
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #CBD4E1;
      border: 0.75px solid #CBD4E1;
      -webkit-transition: .4s;
      transition: .4s;
      &::before {
        position: absolute;
        content: "";
        width: 11px;
        height: 11px;
        top: 50%;
        -webkit-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);  
        transform: translate(0,-50%);
        left: 4px; 
        background-color: #FFF;
        transition: 0.4s;
      } 
      &.round {
        border-radius: 34px;
        &:before {
          border-radius: 50%;
        }
      }
      
    }
  }
}

 

 

.StickyShareBox{ 
  width: 100%; 
  &.ModelSafariBrwer{
    position: absolute;
    .CustomModelContent{
      width: 500px;
      .btn-close{
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 99;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
  &.backdrop__Mngr{
    &:after{
      z-index: 999999;
    }
  }
  .CustomModelContent{
    position: fixed;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    width: 900px; 
    background-color: var(--white-color);  
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 4px;
    &.ShareBooking__Mdl{
      width: 544px;
      z-index: 9999999;
      @media screen and (max-width: 992px) { 
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .ShareHeadTitle{
        p{
          font-family: var(--font-family-01);
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 24px;
          color: var(--theme-text-color-01);
          padding: 24px 24px 8px 24px;
        }
        .CrossCloss{
          cursor: pointer;
        }
      }
      .Sub__heading{
        padding: 0 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: var(--theme-text-color-02);
      }
      .ShareInputArea{
        padding: 0 24px;
        margin-top: 24px; 
        @media screen and (max-width: 992px) {
          padding: 0 16px;
          margin: 24px 0;
          &.content-inner-bdy{
            margin-top: 0;
          }
        }
        .input-group{
          margin-bottom: 24px;
          flex-direction: column;
        }
      }
      .checkboxHld{
        .containerCheckbx{
          color: var(--theme-text-color-01);
          padding-left: 28px;
        }
      }
      .modal-footer{
        padding: 32px 24px;
        justify-content: flex-start;
        border: none;
        flex-wrap: inherit;
        gap: 16px;
        .StoltBtnIconLarge {
          width: 100%;
          margin: 0;
          @media screen and (max-width: 767px) {
            min-width: 123px;
          }
          @media screen and (max-width: 340px) {
            min-width: 100px;
            padding: 0;
          }
        }
      }
      &.Notification_properties{
        width: 855px;
        .ShareCol{
          display: none;
        }
        .Set_notification{
          display: none;
        }
        &.notifi-at-booking-main{
          width: 1000px;
          @media screen and (max-width: 992px) {
            width: 100%;
            .Customise_content{
              margin-bottom: 24px;
              margin-left: 16px;
            }
          }
          .Set_notification{
            display: block;
            margin: 0;
            @media screen and (max-width: 992px) {
              margin: 0 0px 0px 16px; 
            }
          }
          .ShareCol{
            display: block;
          }
          .content-inner-bdy {
            max-height: initial;
        }
        }
        @media screen and (max-width: 992px) {
          width: 100%;
          padding: 0 8px;
          .StickyShareBoxInner{
            border-radius: 8px;
            .ShareHeadTitle{
              p{
                padding: 24px 16px 8px 16px;
              }
            }
          }
        }
        .content-inner-bdy{
          max-height: 360px;
          padding: 0 24px 12px 24px;
          @media screen and (max-width: 992px) {
            padding: 0 16px;
          }
          @media screen and (min-width: 1000px) and (max-width: 1100px) {
            max-height: 950px;
          }
          @media screen and (min-height: 1000px) and (max-height: 1100px) {
            max-height: 680px;
          }
          @media screen and (max-height: 900px) {
            max-height: 500px;
          }
          @media screen and (max-height: 750px) {
            max-height: 360px;
          }
          @media screen and (max-height: 570px) {
            max-height: 275px;
          }
          overflow-y: auto;
          overflow-x: hidden;
          @media screen and (min-width: 1300px) {
            max-height: 400px;
          }
          @media screen and (min-width: 1440px) {
            max-height: 500px;
          }
          .InputWdth{
            width: 360px;
            @media screen and (max-width: 992px) {
              width: 100%;
              padding: 24px 16px;
            }
          }
          .DaysTimeMainHolder{
            display: flex;
            column-gap: 68px;
            @media screen and (max-width: 992px) {
              flex-direction: column;
              margin-top: 24px;
            }
          }
          .optionhldr{
            display: flex;
            column-gap: 24px;
            @media screen and (max-width: 992px) {
               flex-direction: column;
               width: 100%;
               .TitleMobileDevice{
                height: 40px;
                background: #F1F4F9;
                display: flex;
                align-items: center;
                padding: 8px 16px; 
                line-height: 24px !important;
                font-weight: 700;
                font-size: 16px !important;
               }
            }
            .col-lft{
              width: 100%;
            }
            .col-rgt{
              width: 100%;
              @media screen and (max-width: 992px) {
                margin-top: 24px;
              }
            }
            .opt-main{
              ul{
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                @media screen and (max-width: 992px) { 
                  row-gap: 0; 
                }
                padding-top: 0 !important;
                li{
                  @media screen and (max-width: 992px) { 
                    padding: 16px 16px;
                    border-bottom: 1px solid #F1F4F9; 
                    color: #27364B;
                      .checkboxHld {
                        .containerCheckbx {
                          padding-left: 36px;
                      }
                    }
                  }
                }
                &.FrequencyOpt{
                  padding-top: 8px !important;
                  @media screen and (max-width: 992px) {
                    padding-top: 0 !important;
                  }
                }
              }
              .filtersMain{
                display: inline-flex; 
                column-gap: 12px;
                padding-top: 8px;
                flex-wrap: wrap;
                row-gap: 16px;
                @media screen and (max-width: 992px) { 
                  display: none;
                }
                .ng-select-parent{
                  ng-select{
                    width:fit-content;
                  }
                }
              }
            }
          }
        }
        .SeparatorLine{
          margin: 24px 0;  
          border: 1px solid #DBE3ED;
        }
        .DaysTimeMain{
          padding-top: 74px;
          display: flex;
          column-gap: 34px;
          &.MobileDayTime{
            @media screen and (max-width: 992px) {
              padding-top: 0;
              .optionhldr{
                padding-left: 0;
                border-bottom: none;
              }
            }
          }
          .DaysCol{
            @media screen and (max-width: 992px) {
              display: none;
            }
            .CheckbxMain{
              .CheckOpt{
                padding-bottom: 20px;
                &.head{
                  padding-bottom: 4px;
                }
              }
            }
          }
          .TimeCol{
            .timeTextLimit{
              max-width: 400px;
              .ng-option{
                padding-right: 16px !important;
                span {
                  overflow: hidden;
                }
              }
            }
            @media screen and (max-width: 992px) {
              display: none;
            }
            .head{
              padding: 5px 0 8px 0;
            }
          }
        }
        .modal-footer {
          padding: 16px 24px 16px 24px;
          @media screen and (max-width: 992px) {
            padding: 48px 16px 16px;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            button{
              min-width: 100%;
            }
          }
      }
      }
    }
    @media screen and (max-width: 992px) { 
      margin: 0 auto;
      padding: 0 24px;
      width: 100%;
      background: transparent;
      top: 50px;
      left: 0;
      transform: inherit;
      .StickyShareBoxInner{
        background-color: var(--white-color);
        border-radius: 4px;
      }
      .mailertableview{
        width: 100% !important;
      }
    }
    &.modelfleetmap{
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      border-radius: 0;
      padding: 32px;
      @media screen and (max-width: 992px) {
        padding: 16px; 
        top: 0;
      }
      .StickyShareBoxInner{
        width: 100%;
        height: 100%;
        background: var(--white-color);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        .mapbdy{
          &.mapbdyup{
            padding: 0 24px;
            @media screen and (max-width: 992px) {
              padding: 0 16px;
            }
          }
          app-agm-map{
            agm-map{
              @media screen and (max-width: 992px) {
              position: static; 
              height: 80vh;
              }
            }
          }
        }
      }
    }
  }
    &::before{
      @media screen and (max-width: 992px) {
        position: absolute;
        content: "";
        width: 100%;
        height: 79px;
        background-color: #000;
        opacity: 0.5;
        left: 0;
        top: -80px;
        z-index: 9999; 
      }
    } 

    &:after{
      position: fixed;
      content: "";
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: .5;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 9999; 
    } 
    &.backdrp-st{
      &:after{ 
        @media screen and (min-width: 768px) and (max-width: 992px) {
           z-index: 999999;
        }
      } 
    }
    &.user-feedback-model{
      &:after{ 
        opacity: 1;
        background: rgba(100, 116, 139, 0.33);
      }  
      .CustomModelContent{
        width:792px;
        border: 1px solid #D5DCE0;
        box-shadow: 0px 10px 15px rgb(0 0 0 / 6%);
        border-radius: 4px;
        @media screen and (max-width: 992px) { 
        border:none;
        box-shadow:none;
        border-radius: 0px;
        top: 0;
        height: 100%;   
        width: 100%;
        &.welcome-mdl-cnt{
          padding: 0;
        }
        }
      }
    }
  .ShareHeadTitle{
    font-style: normal;
    font-weight: bold;
    margin: 0px; 
    color: var(--theme-text-color-01);
    p{
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 24px 15px 16px;
      font-size: 18px; 
      &.titleFletMp{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px; 
        color: #000000;
        padding: 12px 24px 12px 24px;
        font-family: var(--font-family-01);
        @media screen and (max-width: 992px) {
          padding: 12px 16px 12px 16px;
        }
      }
      .btn-close{
        cursor: pointer; 
        font-size: 15px;
      }
    } 
  }
  .ShareInputArea{
    .input-group{   
      .group-text-hld{
        display: flex;
        align-items: center;
        padding-right: 24px;
        position: absolute;
        right: 0;
        height: 30px;
        background: var(--white-color);
        top: 5px;
        width: 75px;
        text-align: right;
        justify-content: flex-end;
        z-index: 99; 
        svg{
          width: 8px;
          height: 8px;
          opacity: .5;
          cursor: pointer;
          &:hover{
            opacity: 1;
          }
        }
        .input-group-text{
          color: var(--front-color-defolt04);
          background: no-repeat;
          border: none;
          font-size: 13px;
          padding:0 5px 0 0;
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }

      &.TextareaHld{ 
        textarea{ 
          resize: none;
          padding-bottom: 24px !important;
          height: 300px;  
        } 
      }
 
    }
  }
  angular-editor{
    width: 100%;
    .angular-editor-textarea{
      resize: none; 
      height: 330px !important;
      @media screen and (max-width: 1300px) { 
        height: 56vh !important;
      }
      @media screen and (max-width: 767px) { 
        height: 40vh !important;
      }
      min-height: 50px !important;
      &:focus{
        outline: none;
      }
      border: none !important;
      span{
        color: var(  --theme-text-color-01);
      }
      a{
        color: var(  --theme-text-color-01);
        text-decoration: none;
      }
    }
  }
  .SharefooterArea{
    .ButtonSeparator{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 24px;
      .StoltBtnIconLarge{
        padding: 6px 10px;
        min-width: 100px;
        min-height: 30px;
        margin-right: 12px;
        &:last-child{
          margin-right: 0;
        }
        .sendImg{
          width: 12px;
          margin-left: 8px;
        }
      }
    }
  }
}

.MonthYearlyDiv{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;
  @media screen and (max-width: 992px) { 
    align-items: flex-start;
    justify-content: flex-start; 
    margin-top: 0; 
  }
  ng-select{
    margin-right: 16px;
    min-width: 100px;
    @media screen and (max-width: 992px) { 
      margin-right: 0;
      min-width: 100%;
      margin-top: 8px;
    }
    .ng-select-container{
      padding: 0px 12px !important;
      padding-right: 34px !important;
      .ng-input input {
        padding: 8px 12px !important; 
        padding-right: 34px !important;
      }
    }
  }
}


.form-row-last{
  .form-group{
    ng-select{
      ng-dropdown-panel{ 
        right: 0 !important;
      } 
    }
  }
}

.error{
  color: var(--theme-error-color);
}
.req{
  color: var(--theme-error-color);
  font-style: normal;
}



.suggestion__drpdwn{
  position: absolute;
  z-index: 99999;
  background-color: var(--white-color);
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  margin: -24px 0 0 0; 
  &.top_direction_open{
    bottom: 0;
    margin-bottom: 107px;
    box-shadow: 0 0px 6px rgba(32, 33, 36, 0.28); 
  }
  @media screen and (max-width: 992px) {
    max-width: 85%;
  }
  .list__item{
    border-bottom: var(--ng-select-border);
    color: var(--theme-text-color-02); 
    font-size: 14px;
    padding: 7px 16px;
    cursor: pointer;
    span{
      display: flex;
      flex-direction: column;
    }
    &:hover{
      color: var(--theme-text-color-01);
    }
  }
}



/**------- Date Range Picker Customization -------**/ 
@mixin mat-date-input {
  font-weight: 400;
  font-size: 16px;
  font-family: var(--font-family-01);
  letter-spacing: 0.2px;
  color: var(--theme-text-color-01) !important;
}

.mat-datepicker-content{
  box-shadow: none;
  filter: drop-shadow(0px 10px 15px rgba(15, 26, 42, 0.1));
  .mat-calendar{
    height: auto !important;
    @media screen and (max-width: 767px) {
       width: 100% !important;
    }
  }
}


.cdk-overlay-container{
  z-index: 99999999;
  snl-toast{
    margin: var(--spacing-16) var(--spacing-16) 0 0;
    .container_title_cross{
      cursor: pointer;
    }
  }
}
mat-form-field{
  width: 100%;
  max-width: 100%;
  .mat-form-field-wrapper{
    padding: 0;
    .mat-form-field-flex{
      padding: 0;
      background: none;
      .mat-date-range-input {
        height: 48px;
        background: var(--white-color);
        border: 1px solid #CBD4E1;
        border-radius: 6px;
        &.mat-date-range-input-hide-placeholders{
          border-color: var(--text-color-defolt);
        }
        .mat-date-range-input-container{
          height: 48px; 
          padding: 0 12px;
          .mat-date-range-input-start-wrapper{
            .mat-date-range-input-mirror{
              margin-right: 16px;
            }
          }
          .mat-date-range-input-inner{
            @include mat-date-input; 
            font-weight: 700 !important; 
            &::placeholder{
              @include mat-date-input;
            }
          }
        }
       }
      .mat-form-field-infix{
        border: none;
        padding: 0;
        .mat-form-field-subscript-wrapper{
          display: none;
        }
      }
    }
    .mat-form-field-label{
      @include mat-date-input;
      top: 27px;
      margin: 0 0 0 16px;  
      transform: inherit !important;
      
    }  
    .mat-form-field-invalid{
      .mat-form-field-label {
          color: #f44336 !important;
      }
    } 
    .mat-datepicker-toggle{
      position: absolute;
      top: 0;  
      right: 2px; 
      .mat-focus-indicator{
        background: transparent;
        width: 45px;
        height: 48px; 
        line-height: 48px; 
        background-image: var(--ng-calendar-icon);
        background-repeat: var(--ng-select-background-repeat);
        background-size: 16px;
        background-position: right 15px top 16px;
        svg{
          display: none;
          opacity: 0;
        }
        .mat-button-ripple, .mat-button-focus-overlay{
          display: none;
        }
      }
    }
  }
  .mat-form-field-underline{
    display: none;
  }
  &.mat-form-field-two{ 
    .mat-form-field-wrapper{
      padding: 0;
      .mat-form-field-flex{
        padding: 0;
        background: none;
        .mat-date-range-input {
          padding-top: 3px;
          height: 56px;
          background: var(--Bg-color-defolt05); 
          border: none;
          border-bottom: 1px solid var(--theme-light-grey-color);
          border-radius: 4px 4px 0px 0px;
          .mat-date-range-input-container{
            height: 56px;
            padding: 0 12px; 
            .mat-date-range-input-start-wrapper{
              .mat-date-range-input-mirror{
                margin-right: 4px;
              }
            }
            .mat-date-range-input-inner{
              @include mat-date-input; 
              font-weight: 400 !important;  
              &::placeholder{
                @include mat-date-input;
              }
            }
          }
         }
        .mat-form-field-infix{
          border: none;
          padding: 0;
          .mat-form-field-subscript-wrapper{
            display: none;
          }
        }
      }
      .mat-form-field-label{
        position: absolute;
        z-index: 9;  
        transform: inherit !important;  
        color: var(--front-color-defolt06) !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.2px;
        margin-top: 2px;
      }  
 
      .mat-datepicker-toggle{
        position: absolute;
        top: 5px;  
        right: 2px;
        .mat-focus-indicator{
          background: transparent;
          width: 45px;
          height: 48px; 
          line-height: 48px; 
          background-image: var(--ng-calendar-icon2);
          background-repeat: var(--ng-select-background-repeat);
          background-size: 16px;
          background-position: right 12px top 16px;
          svg{
            display: none;
            opacity: 0;
          }
          .mat-button-ripple, .mat-button-focus-overlay{
            display: none;
          }
        }
        &.mat-datepicker-toggle-active{
          .mat-focus-indicator{
            background-image: var(--ng-calendar-icon2-active);
            background-size: 17px;
          }
        }
      }
    } 
    &.datepickerError{
      .mat-form-field-wrapper {
        .mat-form-field-label{
          color: var(--primrose-yellow-color) !important;
        }
        .mat-form-field-flex {
          .mat-date-range-input {
            border-bottom: 2px solid var(--primrose-yellow-color) !important;
          }
        }
      } 
    }
    &.mat-form-field-should-float{
      .mat-form-field-wrapper {
        .mat-form-field-label{
          color: #64748B !important; 
          font-size: 14px !important;
          line-height: 24px !important; 
          margin-left: 12px;
          top: 12px;
        }
      }
    }
    &.mat-form-field-invalid{
      .mat-date-range-input {
        border-color: #CF0027 !important;
      }
    } 
    &.mat-form-field-hide-placeholder{
      &::after{
        position: absolute;
        content: "";
        z-index: 9;
        color: var(--theme-text-color-01);
        letter-spacing: 0.2px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        left: 12px;
        top: 18px;  
      }
    }
  }
}

.date-range-sustainability{
  &.globalSrcDateR{
    mat-form-field{ 
      .mat-form-field-wrapper{ 
        .mat-form-field-flex{ 

          .mat-date-range-input{ 
            .mat-date-range-input-container{ 
              padding: 0 12px;
            }
          }
        }
        .mat-datepicker-toggle{
          .mat-focus-indicator {
            background-position: right 10px top 14px;
        }
        }
      }
    }
    &.AllTextWidth{
      .mat-form-field-type-mat-date-range-input {
        .mat-form-field-infix{
          width: 80px !important;
        }
      }
    }
  }
  .All_Lablel{
    display: none;
  }
  &.mobile-date-range{ 
    @media screen and (max-width: 992px) {
      padding: 16px;
      .mat-form-field-suffix{
        position: static;
      }
      .mat-form-field-should-float{
        .AllValue{
          display: none;
        }
      }
      &.FullClickable{
        mat-form-field{
          .mat-form-field-wrapper{
            .mat-datepicker-toggle{
              width: 100%;
              text-align: right;
            }
          }
        }
      }
    }
  }
  &.AllTextWidth{
    .All_Lablel{
      display: inline-block;
    }
    .mat-form-field-type-mat-date-range-input {
      .mat-form-field-infix{
        width: 76px !important;
      }   
    }
  }
  mat-form-field{ 
    .mat-form-field-wrapper{ 
      .mat-form-field-flex{ 
        .mat-date-range-input{ 
          .mat-date-range-input-container{ 
            padding: 0 12px;
            cursor: pointer;
            .mat-date-range-input-inner{
              color: var(--ng-select-color) !important;
              cursor: pointer;
            } 
          }
        }
      }
      .mat-form-field-label{
        margin-left: 16px;
        color: var(--ng-select-color) !important;
        margin-top: 1px;
        @media screen and (max-width: 992px) {
          margin-top: 2px; 
        }
        .AllValue{
          color: var(--ng-select-color) !important;
          font-weight: bold;
          margin-left: 2px; 
        }
      }
      .mat-date-range-input-start-wrapper{
        .mat-date-range-input-mirror {
          margin-right: 3px !important;
          color: var(--ng-select-color) !important;
        }
      }
      .mat-datepicker-toggle{
        .mat-focus-indicator { 
          background-position: right 10px top 16px;
      }
      }
    } 
  }
  .mat-form-field-type-mat-date-range-input {
    .mat-form-field-infix{
      width: 256px !important;
    }   
  }  
  &.SelectedDate{
    .mat-form-field-suffix{
      position: absolute;
      top: 0;
      right: 2px;
    }
  }
  &.LoadPortEta{
    mat-form-field{ 
      &.mat-form-field-should-float{
        .mat-form-field-infix {
          width: 255px !important;
        }
        .AllValue{
          display: none;
        }
      }
    }
    .mat-form-field-type-mat-date-range-input{
      .mat-form-field-infix {
        width: 110px !important;
      }
    }
  }
  &.DischargePortEta{
    mat-form-field{ 
      &.mat-form-field-should-float{
        .mat-form-field-infix {
          width: 255px !important;
        }
        .AllValue{
          display: none;
        }
      }
    }
    .mat-form-field-type-mat-date-range-input{
      .mat-form-field-infix {
        width: 135px !important;
      }
    }

  }
}
 

.mat-datepicker-actions{
  column-gap: 12px;
  padding: 8px 12px 12px 12px !important; 
  @media screen and (max-width: 992px) {
    justify-content: space-between !important;
    button{
      min-width: 48% !important;
    }
  }
  .StoltBtnIconLarge{
    min-width: 130px;
  }
}
.mat-calendar-content {
  padding: 0 12px 8px 12px !important; 
}

.FloatingLbl{
  @include filter-text-before;
}



.mat-calendar-table-header {
  th {
    font-size: 13px;
    font-weight: 500;
  }
}
.mat-calendar-body-selected {
  background-color: var(--text-color-defolt); 
}

.mat-calendar-header{
  padding: 8px 16px 0 16px !important; 
  button{
    &.mat-focus-indicator{
      border-radius: 6px; 
      background: none;
      border: none;
    }
    &.mat-calendar-period-button{
      border: 1px solid #CBD4E1; 
      color: var(--theme-text-color-01);
      font-family: var(--font-family-01);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px; 
      letter-spacing: 0.2px;
    }
  }
}

.HideSeparator{
  .mat-date-range-input-separator{
    display: none;
  }
}

.CommonErrorMsg{
  color: #CF0027;
  font-size: 14px;
  margin-top: 4px;
}

.ng-option:has(.option-disabled) {
  pointer-events: none;
}

/* Input Search Common */
.CommonInputSearch{
  input{
    min-width: 329px;
    border: var(--spacing-1) solid #DDE2E5;
    padding: 12px 42px 12px 16px;
    height: var( --spacing-48);
    color: var(--font-color-primary);
    outline: none;
    border-radius: var(--spacing-4);
    background-image: var(--SearchIconBG4) !important;
    background-repeat: no-repeat;
    background-size: var(--spacing-16);
    background-position: right 16px top 15px;
    &::placeholder{
      color: var(--font-color-neutral);
    }
  }
  &.SearchCol-200{
    input{
      border: var(--spacing-1) solid var(--neutral-9);
      width: 200px;
      min-width: 200px;
      &::placeholder{
        color:var(--font-color-secondary);
      }
    }
  }
  &.SearchCol-240{
    input{ 
      width: 240px;
      min-width: 240px; 
    }
  }
  &.SearchCol-280{
    input{ 
      min-width: 280px; 
    }
  }
}
 




/* --------- SNL INPUT CUSTOMIZATION ---------*/
snl-input{
  .inp{
    .inp__container{
      .inp__container--label{
        //top: 28px;
      }
    }
    .inp__suffix--wrapper{
      margin: 0 14px 0 0;
      .inp__suffix--clear-icon{
        margin: 0;
      }
    }
    &.inp__error{ 
      background: var(--cross-error) no-repeat center right 14px !important; 
      .inp__suffix--wrapper{
        margin-right: 40px;
      }
    }
  }
  .inp__helperText--error{ 
    padding-left: 0 !important;
    margin-top: 4px;
  }
}
.snl-checkbox-mobile{
    .container__checkbox{
      .container__checkbox--wrapper{
        input{
          @media screen and (max-width: 992px) {
            width: 20px !important;
            height: 20px !important;
            background: #FFFFFF;
            border: 1.4px solid #94A3B8 !important;
            box-sizing: border-box;
            border-radius: 4px !important;
            &:checked{
              border-color: var(--primary-default) !important;
              &:before{
                left: 6px !important;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
}
.inp__container--label{
  top: 28px !important;
  &.inp__container--label-float{
    top: 7px !important;
  }
}

.DateColumn{
  &.error-message{
    mat-form-field {
      .mat-form-field-wrapper {
        .mat-datepicker-toggle {
          .mat-focus-indicator{
            background-image: var(--ng-calendar-icon-error) !important;
          }
        }
      }
    }
}
  mat-form-field{
    height: 100%;
    border: var(--spacing-1) solid var(--neutral-8);
    border-radius: var(--spacing-4);
    &.mat-form-field-should-float{
      border-color: var(--primary-default);
      .mat-form-field-wrapper {
        .FloatingLbl {
          margin-top: 0;
          font-size: 12px;
          color: var(--primary-default);
         }
      }
    }
    &.mat-form-field-disabled{
      cursor: not-allowed;
      .mat-button-disabled{
        cursor: not-allowed !important;
      }
      .FloatingLbl{
        color: var(--font-color-disabled);
      }
    }
    .mat-form-field-wrapper {
      height: 100%;
      .FloatingLbl {
        margin-top: 21px;
        font-size: 16px;
      }
      .mat-form-field-flex{
        height: 100%;
      }
      .mat-form-field-infix{
        height: 100%;
        width: 100%;
        @media screen and (max-width: 350px) {
          width: 90px;
        }
      }
      .mat-form-field-suffix{
        position: relative;
        top: -16px;
        .mat-datepicker-toggle{
          position: static !important;
        }
      } 
      .mat-datepicker-input{
        padding: 0 16px;
        font-weight: 700;
        height: 100%;
        pointer-events: none; 
        max-width: 100px;
      }
    }
  }
}
snl-input{
  .inp__container{
    .inp__container--input{
      font-family: var(--font-family-roboto-bold) !important;
    }
  }
}

