  
  /* ---------------------Snl Components Library Links--------------------- */
  @import "../node_modules/snl-complib/themes/theme-colors.scss";
  @import "../node_modules/snl-complib/themes/typography.scss";
  @import "../node_modules/snl-complib/themes/spacing.scss";
  @import "../node_modules/snl-complib/themes/common.scss";
  @import "../node_modules/snl-complib/themes/snl-complib.scss";
  @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");


  /* ---------------------Fonts Roboto--------------------- */
  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-ThinItalic.ttf) format("truetype");
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-LightItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
  }


  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-MediumItalic.ttf) format("truetype");
    font-weight: 500;
    font-style: italic;
  }


  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-BoldItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-Black.ttf) format("truetype");
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(/assets/fonts/Roboto/Roboto-BlackItalic.ttf) format("truetype");
    font-weight: 900;
    font-style: italic;
  }
 

/* ---------------------Fonts PTSerif--------------------- */
  @font-face {
    font-family: 'PT Serif';
    src: url(/assets/fonts/PT_Serif/PTSerif-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'PT Serif';
    src: url(/assets/fonts/PT_Serif/PTSerif-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'PT Serif';
    src: url(/assets/fonts/PT_Serif/PTSerif-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'PT Serif';
    src: url(/assets/fonts/PT_Serif/PTSerif-BoldItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic;
  }


  /* ---------------------Fonts Cabin--------------------- */
  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-MediumItalic.ttf) format("truetype");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-SemiBoldItalic.ttf) format("truetype");
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cabin';
    src: url(/assets/fonts/Cabin/Cabin-BoldItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic;
  }


  /* ---------------------Fonts Avenir--------------------- */
  @font-face {
    font-family: 'Avenir';
    src: url(/assets/fonts/Avenir/AvenirLTStd-Roman.otf);
  }


  /* ---------------------Fonts Inter--------------------- */
  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
  }   

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-SemiBold.ttf) format("truetype");
    font-weight: 200;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-ExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter', sans-serif;
    src: url(/assets/fonts/Inter/Inter-Black.ttf) format("truetype");
    font-weight: 900;
    font-style: normal;
  } 

  /* ---------------------Fonts Lato--------------------- */
  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
  }   

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-ThinItalic.ttf) format("truetype");
    font-weight: 100;
    font-style: italic;
  }   

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
  }   

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-LightItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
  }  

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }   

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
  }  

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  }   

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-BoldItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic;
  }  

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-Black.ttf) format("truetype");
    font-weight: 900;
    font-style: normal;
  }   

  @font-face {
    font-family: 'Lato';
    src: url(/assets/fonts/Lato/Lato-BlackItalic.ttf) format("truetype");
    font-weight: 900;
    font-style: italic;
  }  



  @import url('./styles/themes/reusableTheme.scss'); 
  .default {
  @import './styles/themes/default';
  }

  .dark {
  @import './styles/themes/dark';
  }
  
  /* Importing Bootstrap SCSS file. */
  @import "./node_modules/bootstrap/scss/bootstrap";

  /* Importing Datepicker SCSS file. */
  @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";



/* Mixin Function Scss */
    @mixin browser_transition($value-trans: 0.5s) {
      transition: all $value-trans ease-in-out;
      -webkit-transition: all $value-trans ease-in-out;
      -moz-transition: all $value-trans ease-in-out;
      -o-transition: all $value-trans ease-in-out; 
    }

    @mixin brdr_radius($value-radius: 8px) {
      border-radius: $value-radius;
      -webkit-border-radius: $value-radius;
      -moz-border-radius: $value-radius;
      -o-border-radius: $value-radius;
    } 
/* Mixin Function Scss End */

 


/* Stolt Reusable Style */

.StoltWrapper{
  display: flex;
  flex-direction: column; 
  min-height: 100%; 
  position: relative;
}
.StoltMainContent{
  flex-grow: 1;
}
.CommonCardContent{
  background: #FFFFFF;
  @include brdr_radius();
  padding: 24px 32px; 
}
a{cursor: pointer;}
.CursorDefault{cursor: default;}
.Cursorpointer{cursor: pointer;}
ul{
  margin: 0px !important;
  padding: 0px !important;
  list-style: none;
  li{
    outline: none;
    &:hover{
      outline: none; 
    }
  }
}
 .z-index-cstm{
   z-index: 999999 !important;
 }

.textdeco-none{
  text-decoration: none !important;
} 
.imgwd_100{
width: 100%;
} 
.text_left{
  text-align: left;
}
.text_right{
  text-align: right;
} 
.break-break-clc{word-break: break-word;}
.padding-bottom-20{padding-bottom: 20px;} 
.StoltCommonContentArea{
  margin:0;
  width: 100%;
  padding:var(--spacing-32) var(--spacing-24); 
  min-height: 100vh;
  transition: all 0.3s; 
  @media screen and (max-width: 992px) { 
    padding:var(--spacing-16); 
  }
}
@media screen and (max-width: 992px) { 
  .ConnonOverlay{
    position: relative;
    height: auto !important;
    &:before{
        position: fixed;
        content: "";
        background: #000000;
        opacity: 0.5;
        height: 100%;
        width: 100%; 
        z-index: 9999999;
        top: 0;
    }
    &.ConnonOverlayRemove{
      &:before{
        display: none;
      }
    }
} 
  .M_CommonHidden{
    display: none !important;
  } 
 
  .TabletMt_20{
    margin-top: 20px;
  }
  .MobileRelative{
    position: relative;
  }
 
}
  
.LargeDeviceshide{
  display: none !important;
  @media screen and (max-width: 992px) { 
    display: flex !important;
  }
}

.SmallViewInfo{ 
  display: none;
}

.MobileDataBxHolder{
  padding: var(--spacing-4) var(--spacing-16);
  flex-direction: column; 
  .download-scc-row{
    margin-top: var(--spacing-16);
    a{
      span{
        color: var(--primary-default);
        font-weight: 700;
      }
    }
  }
  .ModelDataBdr{
    color: var(--font-color-primary) !important;
    border-bottom: 1.5px dashed currentColor;
  }
  .VoyageNm{ 
    gap: 4px;
    span{
      font-weight: 700;
    }
    .Semi-BoldValue{
      font-weight: 500;
      color: var(--font-color-primary);
    }
  }
  .h_300{
    min-height: 300px;
  }
  span{
    font-weight: 400;
    font-size: 14px;
    line-height: var(--spacing-24);
    display: flex;
    align-items: center; 
    color:var(--font-color-secondary);
    .Co2divMobile{
      position: relative;
      .co2div{
          left: 0;
          margin-top: 25px;
          top: 0;
          display: none; 
      }
      &:hover{
        .co2div{ 
          display: block;
      }
      }
    }

    &.ms_2{
      margin-left: var(--spacing-2);
    }
    &.cargo-delay{
      font-size: var(--spacing-12);
      line-height: 14px;
    }
  }
  .MobileCommonDataBx{
    padding: var(--spacing-16);
    min-height: 100px;
    background: var(--neutral-12);
    border: var(--spacing-1) solid var(--neutral-9);  
    @include brdr_radius();
    margin-bottom: var(--spacing-16);
    position: relative; 
    &.row-disabled{
      width: 100%;
    }
    &.selectedBx{
      background-color: var(--neutralN10-color);
    }
    a{
      text-decoration: none !important;
    }
    .border-dash-b{
      border-bottom: 1.5px dashed currentColor;
    }
    .NominationsCard{
      .MobileBxHead{
        height: 6px;
      }
    }
     .user-list-mobile{
       row-gap: var(--spacing-12);
        .NameUser{
          color: var(--primary-default);
          text-decoration: underline;
        }
        p{
          color: var(--font-color-primary);
          word-break: break-word;
        }
        .drop-down-edit{
          span{
            font-weight: 700;
            font-size: var(--spacing-16);
            line-height: var(--spacing-24);
            color: var(--font-color-primary);
          }
          .form-control{
            ng-select{
              background: none;  
            }
          }
          &.edit-disabled{
            ng-select{
              pointer-events: inherit;
              opacity: 1;
            }
          }
        }
     }
    .MobileCommonDataBxIn{
      &:last-child{
        .MobileContentMdBx-Sec{
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        } 
      }
    }  
    .Stolt-Skeleton{
      min-height: 250px;
      border-radius: var(--spacing-8);
    } 
    .MobileBxHead{
      display: flex;
      flex-direction: column;
      .MobileBxNum{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.CardHeader{
          min-height: var(--spacing-48);
          .checkboxHld{
            min-height: var(--spacing-24);
          }
          .containerCheckbx{
            top: 0;
            padding-left: 48px;
            .checkmark{
              top: var(--spacing-2);
              left: 14px;
              &.tps__5{
                 top: 5px;
              }
            }  
            a{color: #27364B;} 
          } 
          .UnredDot{
            background-color: #CBD4E1;
            height: 8px;
            min-width: 8px;
            border-radius: 50%;
            margin: 0 12px;
            display: none;
          .InquiryTbd{
            color: #27364B;
          }
          .arrowhead{
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      } 
    } 
    .UnreadCard{
      .fw{
        font-weight: 700;
      }
      .UnredDot{
        display: block !important;
      }
    }
    }
    .CardDataHld{
      border-bottom: var(--spacing-1) solid var(--neutral-9);
      padding-bottom: var(--spacing-16);
      margin-bottom: var(--spacing-16);
      .CardTitle{ 
        line-height: var(--spacing-32);
        color: var(--font-color-primary);
        font-weight: 700;
        font-size: 20px;
        margin: 0;
      }
      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;  
      }
    }
    .PlaceCol{
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-16);
      margin: var(--spacing-8) 0;
      span{
        font-size: 20px;
        line-height: var(--spacing-32);
        font-weight: 400;
        width: 45%;
        color: var(--font-color-primary);
        &.arrow-mid{
          width: 10%; 
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .MobileContentMdBx{ 
      &.MobileContentMdBx-Sec{
        border-bottom: var(--spacing-1) solid var(--front-color-defolt08);
        padding-bottom: 20px;
        margin-bottom: 20px; 
      }
      .MobileContentInner{ 
        margin-bottom: var(--spacing-8);
        .info-icon-r-align{
          @media screen and (max-width: 767px) { 
             justify-content: space-between;
          }
        }
        &.MobileContentInner2{
          margin-bottom: var(--spacing-12) !important; 
          &.mb-0{
            margin-bottom: 0 !important;
          }
          &.loadingSpc{
            margin-bottom: var(--spacing-12) !important;
          }
          .cargo-delay{
            margin-left: var(--spacing-8);
            &.ms_2{
              margin-left: var(--spacing-2);
            }
          }
        }
        &:last-child{
          margin-bottom: 0;
        }
        svg-icon{
          margin-left: var(--spacing-4);
          position: relative;
          top: -1px;
        }
      }
      .Semi-BoldValue{
        color: var(--font-color-primary); 
        flex-direction: row;
        align-items: center;
        font-size: var(--spacing-16);
        font-weight: 700;
        &.mrt8{
          margin-top: var(--spacing-8);
        }
         
        &.StatusLabel{
          justify-content: space-between;
        }
      }
      &.MobileContentFtBx{
        margin-top: 20px;
        .MgoLsfoHldr{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          .MobileContentInner2{
            margin-bottom: 0 !important;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &.download-scc{
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              a{
                color: var(--primary-default);
              }
            }
            .Semi-BoldValue{
              &.StatusLabel{
                margin-left: 0;
              }
            } 
          }
        }
        .MobileContentInner{
          display: flex;
          align-items: flex-start;
          margin-bottom: var(--spacing-4);
        }
        &.CargoesAreaHld{
          margin-top: 22px;
          .MobileContentInner{
            flex-direction: column; 
            align-items: flex-start;
            margin-bottom: 0;
            &.cp-cargoHd{
              flex-direction: row;
              justify-content: space-between;
            }
            .CargoesNameValue{
              display: flex;
              justify-content: space-between;
              width: 100%;
              span{
                color: var(--font-color-primary);
                &.Semi-BoldValue{
                  font-weight: 500;
                  display: flex;
                  align-items: flex-start;
                }
                &.greyLeft{
                  color: var(--font-color-secondary);
                  width: 180px; 
                  display: flex;
                  flex-wrap: wrap;
                }
              }
              &:first-child{
                span{
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    } 
  }
} 
.border-dash-b{
  border-bottom: 1.5px dashed currentColor;
}

.cargoesselector{
  snl-checkbox{
    .container__checkbox{ 
      gap: 24px; 
    }
   }
}

.disabled_Checkbox{
  cursor: not-allowed;
  opacity: 0.5;
  .container__checkbox{
    pointer-events: none !important;
  }
}

body{
  &.custom-date-picker{
    .cdk-overlay-container{
      .cdk-overlay-pane{
        @media screen and (min-height: 600px) and (max-height: 750px) {
          margin-top: -70px !important; 
        }
      }
    }
  }
}

/* Filter Model Design */
.FiltersModelCustom{
  background: #FFF; 
  ng-select{
    &.userl-listmdl{
      @media screen and (max-width: 992px) {
        .ng-dropdown-panel{
          background: none;
          .ng-dropdown-panel-items{
            &.scroll-host{
              margin: 0;
              padding: 0;
            }
          }
          .ng-option{ 
            border-top: 1px solid #F1F4F9;
            &.ng-option-selected{
              span{ 
                font-weight: 700;
                background-image: var(--radiobutton-icon) !important;
              }
            }
          }
        }
      }
     }
  }
  @include brdr_radius(24px 24px 0 0);
  max-height: 92vh;
  @media screen and (max-width: 992px) { 
    @media screen and (orientation:landscape) {
      max-height: 95vh; 
    }
  }
  position: fixed;  
  bottom: -100%; 
  visibility: hidden;
  width: 100%;
  @include browser_transition();
  flex-direction: column;
  z-index: 99999;
  @media screen and (max-width: 992px) { 
    z-index: 99999999;
  }

  &.ModelShow{   
    bottom: 0;
    left: 0;
    visibility: visible; 
  }
  .ModelInnerContent{
    .F_ModelHeader{  
      padding: 0 16px; 
      border-bottom: 1px solid #F1F4F9;
      &.__borderBold{
        border-bottom: 2px solid #F1F4F9; 
      }
      .model-dragger{
        width: 120px;
        opacity: 0.1;
        border: 3px solid #0F1A2A;
        padding: 0;
        height: 0px;
        border-radius: 8px;
        margin: 12px auto 0 auto;
      }
      span{
        font-weight: bold;
        font-size: 20px; 
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 32px;
        color: var(--theme-text-color-01);
        justify-content: center;
        position: relative;
        margin: 12px 0;
        .angle-arrow{ 
          position: absolute;
          left: 0;
          top: -2px;
          z-index: 99999999;
        }
      }
    }
    .BackToPre{
      position: absolute;
      left: 16px;
      margin-top: 4px;
      z-index: 999;
      &.mt18{
        margin-top: 18px;
      }
    }
    .FilterItemsName{
      overflow-y: scroll; 
      padding-bottom: 16px;
      max-height: 70vh;
      &.LaycanList{
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 16px 16px;
        color: #27364B;
        .NameLeft{
          color: #64748B;
        }
        .ListMain{
          &:first-child{
            font-weight: 700;
          }
        }
      }
      &.pb_80{
        @media screen and (max-width: 992px) {
          padding-bottom: 80px;
        }
      }
      &.MobileMUltiSelct{
        &.MultiMoblieSus{
          @media screen and (max-width: 1000px) {
              margin: 16px;
              position: relative;
              min-height: 240px;
              .Confidencedropdown{
                width: 100%;
                @media screen and (orientation:landscape) {
                  height: 100px;
                  overflow-y: auto;
                }
                .Confidencedropftr{
                  button {
                    width: 140px !important;
                  }
                }
                .ConfidenceFltrSlider{
                  margin-bottom: 12px;
                } 
              }  
          }
        }
        &.SettingFlrsMobile{
          .ng-select-multiple{
            .ng-dropdown-panel-items{
              @media screen and (max-width: 992px) {
              max-height: 500px !important;
              @media screen and (max-width: 380px) {
                max-height: 400px !important;
              }
              }
            }
          }
        }
          ng-select{ 
            @media screen and (max-width: 992px) { 
                &.mobiledrpdwnSlct{
                  .ng-dropdown-panel{ 
                    position: static;
                    border: none;
                    box-shadow: none;
                    border-radius: 4px;
                  }
                  &.statusListItm{
                    .ng-option{
                      &:first-child{
                        margin-top: 48px;
                      }
                    }
                  }
                } 
            } 
          &.M_delayFilter{
            .ng-select-multiple{ 
              .ng-dropdown-panel-items {  
              @media screen and (max-width: 380px) {
                max-height: 350px !important;
              } 
              }
            }
          }
        }
        
        @media screen and (max-width: 992px) {
           min-height: 600px;
           overflow-y: inherit; 
           &.mh_310{
            min-height: 310px;
           }
           &.mh_280{
            min-height: 280px;
           }
           &.mh_190{
            min-height: 190px;
           }
           ng-select{  
                background: none;
                border: none;
                &.maduleAccMob{
                  .ng-dropdown-panel-items{
                    @media screen and (max-width: 992px) {
                        max-height: 190px !important;
                    }
                  }
                }
                &.maduleAccMob2{
                  .ng-dropdown-panel-items{
                    @media screen and (max-width: 992px) {
                        max-height: 224px !important;
                    }
                  }
                }
                &.ng-select-container-hide{
                  .ng-select-container{
                    display: none;
                  } 
                }
                &.FixedHdrTxt{
                  .ng-select-container{
                    .ng-value-container{
                      position: fixed;
                      width: 100%;
                      left: 0;
                      background: #fff;
                      border-bottom: 2px solid #F1F4F9;
                      min-height: 48px;
                    }
                  }
                }
                .ng-option { 
                  height: 48px; 
                  line-height: 48px; 
                  font-size: 16px;
                  padding-left: 16px;
                } 
                  .OptionTxt{
                    padding-left: 36px;
                    &.textTinitial{
                      text-transform: initial;
                    }
                    &.OptionTxtSpace16{
                      padding-left: 16px;
                    }
                  }
              } 
              .SelectAllOpt { 
                height: 48px; 
                line-height: 48px; 
                font-size: 16px;
                padding-left: 16px;
                .checkboxHld{
                  .OptionTxt{
                    padding-left: 36px;
                  }
                }
              }
               .ng-select-container{  
                  padding: 0 50px !important;
                  //position: absolute;
                  //top: -75px;
                  z-index: 999999;
                  font-weight: 700;
                  font-size: 24px;
                  background: #fff;
                  overflow: initial;
                  display: none;
                 .ng-value-container{
                   span{
                    width: 100%;
                    display: flex;
                    text-align: center; 
                    justify-content: center;
                   }
                 }
               }
               .ng-select-multiple{
                position: static;
                border: none;
                box-shadow: none;
                width: 100%;
                .ng-dropdown-panel-items { 
                  padding: 0 0; 
                  max-height: 430px !important;
                  @media screen and (max-width: 380px) {
                    max-height: 340px !important;
                  }
                  margin-top: 6px; 
                }
                .ng-dropdown-header {
                  padding: 16px;  
                  border-top: 1px solid #F1F4F9;
                 }
                .checkboxHld{
                  .containerCheckbx{
                    &.tp15 {
                      top: 14px;
                    }
                  }
                }
               }
           }
        }    
      }
      .HeadingTitle{ 
        padding: 12px 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--theme-text-color-01);
        background: #F1F4F9;
        display: flex;
        justify-content: space-between;
        .filter-reset{
          a{
            color: var(--text-color-defolt);
          }
          &.anchor-disable{
            a{
              color: #B8BFCA;
            }
          }
        }
      }
    ul{
      &.Borderseparator{
        border-bottom: 1px solid var(--front-color-defolt08);
        &.SortHlder{
          li{
            gap: 0;
            p{
              &.titlesrt_m{ 
                font-weight: 500;
                font-size: 16px; 
                margin-bottom: 8px;
              }
            }
            .RadioLabel{ 
              display: flex;
            } 
          }
        }
        &.ETA-Change{
          li{
            padding:12px 16px;
          } 
        }
      }
      li{ 
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #27364B;
        padding: 12px 16px;
        border-top: 1px solid #F1F4F9;
        position: relative; 
        display: flex;
        gap: 16px;
        &.small-lst{
          padding: 5px 16px;
          border-top: 0px;
          border-bottom: 1px solid #DBE3ED;
          .doc-name{
            width: 62%;
            @media screen and (min-width: 992px) {
              width: 82%;
            }
          }
          .load-discharge{
            width: 38%; 
            display: flex;
            column-gap: 12px;
            span{
              &:first-child{
                width: 30%;
              }
              &:nth-child(2){
                width: 70%;
              }
            }
          }
        }
        &.active{
          color: var(--text-color-defolt);
          font-weight: bold;
        } 
        .checkboxHld {
          .containerCheckbx{
            top: 2px;
            padding: 0px 0px 0px 36px;
          }
        }
        svg-icon{
          position: absolute;
          right: 16px;
          transform: rotate(180deg);
          &.rotatenone{
            transform: inherit;
          }
        }

      } 
    } 
  } 
  }
  .TimeModelMobile{
    .TitleLabel{
      height: 48px;
      background: #F1F4F9; 
      padding: 16px; 
      a{
        color: #B8BFCA;
        text-decoration: none;
      }
    }
    .TimezoneSelectHide{
      background: none;
      height: 100%;
      .ng-select-container{
        display: none;
      }
     .ng-dropdown-panel{
      position: relative; 
      display: flex;
      flex-direction: column;
      z-index: 9999;
      box-shadow: none; 
      width: 100%;
      margin: 0 0 12px 0;
      border: none;
      .ng-option{ 
        span{ 
          @media screen and (max-width: 992px) {
            background-image: var(--radiobutton-icon-circle) !important;
            padding-left: 34px; 
            background-repeat: no-repeat;
            background-position: center left; 
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            &.SelectedCheck{
              background-image: var(--radiobutton-icon) !important;
            }
          }
        } 
      }
      .ng-dropdown-header{
        padding: 8px 16px;
      }
     }
    }
  }
  .bottom-space{
    padding-bottom: 70px !important;
  }
  .modal-footer-fixed{
    position: fixed;
    background-color: #fff !important;
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    column-gap: 16px;
    padding: 16px !important;
    bottom: 0;
    border: none !important;
    button{ 
      min-width: 47%;
      margin: 0;
    }
  }

  .DocumentationMobileMdl{
    .FilterItemsName {
      &.DocumentationData{
        padding:16px;
        ul {
          li {
            padding: 0 !important;
            border-bottom: 1px solid #DBE3ED;
          }
        } 
      }
      ul{
        li {
          padding: 8px 16px !important;
          .doc-name{
            width: 62%;
            @media screen and (min-width: 992px) {
              width: 82%;
            }
          }
          .load-discharge{
            width: 38%;
            @media screen and (min-width: 768px) {
              width: 18%;
            }
            display: flex;
            column-gap: 12px;
            span{
              &:first-child{
                width: 30%;
              }
              &:nth-child(2){
                width: 70%;
              }
            }
          }
        }
      }
    }
  }


  .TimeZoneDrodwnMn{
    .TimeHeadingMn{
      margin-bottom: 20px !important;
    }
    ng-select{
      .ng-select-container{
        border-color: #CBD4E1 !important; 
      }
      &._Timezone{
        width: fit-content;
      }
      &.ng-select-opened {
        .ng-select-container{ 
          &.ng-has-value{
            border-color: var(--primary-default) !important;
          }
        } 
      }
     .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          span {
            background-image: var(--radiobutton-icon-circle) !important;
            padding-left: 34px; 
            background-repeat: no-repeat;
            background-position: center left; 
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            &.SelectedCheck{
              background-image: var(--radiobutton-icon) !important;
            }
          }
        }
      }
     }
    }
  }

 




/* ------------------------- Mobile draggble bottom to top model start -------------------------  */


@mixin bottom-sheet-cmn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  .header {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    &.bdr-btm{
      border-bottom: 2px solid #F1F4F9;
    } 
    .drag-icon {
      .model-dragger{
        height: 4px;
        width: 120px;
        display: block;
        background: #0F1A2A;
        opacity: 0.1;
        border-radius: 8px;  
      }
      .TitleHdr {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #27364B;
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }
    }
    &.FxHdr{
      position: fixed;
      width: 100%;
      left: 0;
      z-index: 99;
      background: #fff;
      border-bottom: 2px solid #F1F4F9;
    }
  }
  .body {
    @include body-cmn;
    &.CustomHgtLmt{
      max-height: 90vh; 
    }
    &.BtmSpcemdl{
      padding-bottom: 136px;
    }
    &.BtmSpcemdl80{
      padding-bottom: 80px;
    }
    &.full-data-scrollable{
      padding-bottom: 160px;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
    .scroll-host{
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  &.show {
    opacity: 1;
    pointer-events: auto;
    .content{
      transform: translateY(0%);
    }
  }
  &.dragging {
    .content {
      transition: none;
    }
  }
  &.fullscreen {
    .content {
      max-height: 97vh;
      overflow-y: hidden;
    }
  }
  .sheet-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #000;
  }
  .content { 
    @include content-cmn; 
    @include browser_transition();
    &.content-lastactive{
      min-height: 92vh;
    }
    &.CntHeightManager{
      height: auto;
      max-height: 50vh;
      &.CardFull{
        max-height: 92vh;
      }
    }

    &.TBdInfo-content{
      height: auto;
      .body{
        padding: 16px;
        border-top: 1px solid #F1F4F9;
      }
    }
    &.custom-min-hgt{
      min-height: 70vh;
    }
  }
}



.bottom-sheet-time {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  .header-time {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    .drag-icon-time {
      cursor: grab;
      user-select: none;
      padding: 0; 
      .model-dragger-time{
        height: 4px;
        width: 120px;
        display: block;
        background: #C7D0E1;
        border-radius: 8px;  
      }
      .TitleHdr-time {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #27364B;
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }
    }
  }
  .body-time {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &.show-time {
    opacity: 1;
    pointer-events: auto;
    .content-time{
      transform: translateY(0%);
    }
  }
  &.dragging-time {
    .content-time {
      transition: none;
    }
  }
  &.fullscreen-time {
    .content-time {
      max-height: 97vh;
      overflow-y: hidden;
    }
  }
  .sheet-overlay-time {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #000;
  }
  .content-time {
    width: 100%;
    position: relative;
    background: #fff;
    max-height: 100vh;
    height: 50vh;  
    border-radius: 24px 24px 0px 0px;
    transform: translateY(100%); 
    transition: 0.3s ease;
    &.custom-min-hgt{
      min-height: 70vh;
    }
    &.content-lastactive{
      min-height: 92vh
    }
  }
}

/* load port drag css */

.bottom-sheet-port {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  .header-port {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    .drag-icon-port {
      cursor: grab;
      user-select: none;
      padding: 0; 
      .model-dragger-port{
        height: 4px;
        width: 120px;
        display: block;
        background: #C7D0E1;
        border-radius: 8px;  
      }
      .TitleHdr-port {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #27364B;
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }
    }
  }
  .body-port {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &.show-port {
    opacity: 1;
    pointer-events: auto;
    .content-port{
      transform: translateY(0%);
    }
  }
  &.dragging-port {
    .content-port {
      transition: none;
    }
  }
  &.fullscreen-port {
    .content-port {
      max-height: 97vh;
      overflow-y: hidden;
    }
  }
  .sheet-overlay-port {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #000;
  }
  .content-port {
    width: 100%;
    position: relative;
    background: #fff;
    max-height: 100vh;
    height: 50vh;  
    border-radius: 24px 24px 0px 0px;
    transform: translateY(100%); 
    transition: 0.3s ease;
    &.custom-min-hgt{
      min-height: 70vh;
    }
  }
}


/* sustainability Ship drag css */

.bottom-sheet-ship {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  .header-ship {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    .drag-icon-ship {
      cursor: grab;
      user-select: none;
      padding: 0;
      .model-dragger-ship{
        height: 4px;
        width: 120px;
        display: block;
        background: #C7D0E1;
        border-radius: 8px;  
      }
      .TitleHdr-ship {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #27364B;
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }
    }
  }
  .body-ship {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &.show-ship {
    opacity: 1;
    pointer-events: auto;
    .content-ship{
      transform: translateY(0%);
    }
  }
  &.dragging-ship {
    .content-ship {
      transition: none;
    }
  }
  &.fullscreen-ship {
    .content-ship {
      max-height: 97vh;
      overflow-y: hidden;
    }
  }
  .sheet-overlay-ship {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #000;
  }
  .content-ship {
    width: 100%;
    position: relative;
    background: #fff;
    max-height: 100vh;
    height: 50vh;  
    border-radius: 24px 24px 0px 0px;
    transform: translateY(100%); 
    transition: 0.3s ease;
    &.custom-min-hgt{
      min-height: 70vh;
    }
  }
}



/* sustainability Cargo drag css */

.bottom-sheet-cargo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  .header-cargo {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    .drag-icon-cargo {
      cursor: grab;
      user-select: none;
      padding: 0; 
      .model-dragger-cargo{
        height: 4px;
        width: 120px;
        display: block;
        background: #C7D0E1;
        border-radius: 8px;  
      }
      .TitleHdr-cargo {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #27364B;
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }
    }
  }
  .body-cargo {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &.show-cargo {
    opacity: 1;
    pointer-events: auto;
    .content-cargo{
      transform: translateY(0%);
    }
  }
  &.dragging-cargo {
    .content-cargo {
      transition: none;
    }
  }
  &.fullscreen-cargo {
    .content-cargo {
      max-height: 97vh;
      overflow-y: hidden;
    }
  }
  .sheet-overlay-cargo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #000;
  }
  .content-cargo {
    width: 100%;
    position: relative;
    background: #fff;
    max-height: 100vh;
    height: 50vh;  
    border-radius: 24px 24px 0px 0px;
    transform: translateY(100%); 
    transition: 0.3s ease;
    &.custom-min-hgt{
      min-height: 70vh;
    }
  }
}


/* Global search Cargo drag css */

.bottom-sheet-searchCargo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  .header-searchCargo {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    .drag-icon-searchCargo {
      cursor: grab;
      user-select: none;
      padding: 0; 
      .model-dragger-searchCargo{
        height: 4px;
        width: 120px;
        display: block;
        background: #C7D0E1;
        border-radius: 8px;  
      }
      .TitleHdr-searchCargo {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #27364B;
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }
    }
  }
  .body-searchCargo {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &.show-searchCargo {
    opacity: 1;
    pointer-events: auto;
    .content-searchCargo{
      transform: translateY(0%);
    }
  }
  &.dragging-searchCargo {
    .content-searchCargo {
      transition: none;
    }
  }
  &.fullscreen-searchCargo {
    .content-searchCargo {
      max-height: 97vh;
      overflow-y: hidden;
    }
  }
  .sheet-overlay-searchCargo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #000;
  }
  .content-searchCargo {
    width: 100%;
    position: relative;
    background: #fff;
    max-height: 100vh;
    height: 50vh;  
    border-radius: 24px 24px 0px 0px;
    transform: translateY(100%); 
    transition: 0.3s ease;
    &.custom-min-hgt{
      min-height: 70vh;
    }
  }
}



/* Global search Documentation drag css */

.bottom-sheet-searchDoc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  .header-searchDoc {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    .drag-icon-searchDoc {
      cursor: grab;
      user-select: none;
      padding: 0; 
      .model-dragger-searchDoc{
        height: 4px;
        width: 120px;
        display: block;
        background: #C7D0E1;
        border-radius: 8px;  
      }
      .TitleHdr-searchDoc {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #27364B;
        display: flex;
        justify-content: center;
        padding-top: 12px;
      }
    }
  }
  .body-searchDoc {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &.show-searchDoc {
    opacity: 1;
    pointer-events: auto;
    .content-searchDoc{
      transform: translateY(0%);
    }
  }
  &.dragging-searchDoc {
    .content-searchDoc {
      transition: none;
    }
  }
  &.fullscreen-searchDoc {
    .content-searchDoc {
      max-height: 97vh;
      overflow-y: hidden;
    }
  }
  .sheet-overlay-searchDoc {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #000;
  }
  .content-searchDoc {
    width: 100%;
    position: relative;
    background: #fff;
    max-height: 100vh;
    height: 50vh;  
    border-radius: 24px 24px 0px 0px;
    transform: translateY(100%); 
    transition: 0.3s ease;
    &.custom-min-hgt{
      min-height: 70vh;
    }
  }
}

.FilterOverlay{
  @media screen and (max-width: 992px) { 
    position: relative;
    transition: all 2s ease-in-out; 
    &::before, &::after{
      position: absolute;
      content: "";
      background: rgba(39, 54, 75, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; 
      z-index: 9999999;  
    }
    &::after{ 
      height: 80px;
      top: -80px; 
    }
  }
}

.FilterTags{
  display: flex;
  flex-direction: row;
  color: #27364B;
  flex-wrap: wrap;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 16px 24px;
  span{
    padding: 8px 40px 8px 16px;
    background: #FFFFFF;
    border: 1px solid #DDE2E5;
    border-radius: 200px;
    position: relative;
    margin: 0 8px 8px 0;
    &:last-child{
      margin-bottom: 0;
    }
    svg-icon{
      position: absolute;
      right: 20px;
      top: 8px;
    }
  }
}
/* Filter Model Design End */


/* Reusable Design Style End*/

body{
  &.modal-open{
    .StoltBGDefault{ 
      .z-99__st{ 
        @media screen and (max-width: 992px) { 
          z-index: 99 !important;
        }
      }
    }
  }
}

/* Stolt-Skeleton*/
.Stolt-Skeleton{ 
  min-width:50px;
	min-height:10px;  
	display:flex;
  cursor: wait !important;
	flex-direction:column;
  background: #d9d9d9;
  .ng-select-container{
    border: none;
  }
	//background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
	//background-repeat:no-repeat;
	//background-size:450px 400px; 
	//animation: shimmer 1s linear infinite;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
  background-size: 2000px 200px;
  animation: wave-squares 2s infinite ease-out;
  &.Pills-Skeleton{
    position: relative;
    .Stolt-Skeleton{
      height: 23px;
      position: absolute;
      right: 0;
      border-radius: 50px;
      width: 80px;
      margin: -7px -82px 0px 0px !important;
    } 
  }
  &.lg-Skeleton{
    height: 24px;
  }
  &.md-Skeleton{
    height: 20px;
  }
}
@keyframes wave-squares {
  0% {
      background-position: -1000px 0;
  }
   100% {
      background-position: 1000px 0;
  }
}


/* Stolt-Skeleton End*/


 



/* Bottom Sticky Bar Style */
.action-panel-bottom{
  position: sticky;
  bottom: 0;
  z-index: 99;
  box-shadow: 0px -5px 15px rgba(30, 33, 46, 0.06) !important; 
  &.z-indexremover{
    @media screen and (min-width: 1024px) {
      z-index: 0;
    }
  }
  @media screen and (max-width: 992px) { 
    &.action-panel-bottom-mobile{
      padding: var(--spacing-16);
      div{
        min-width: 100%;
      }
      .mobile-btn-pnl{
        flex-direction: column-reverse;
        gap: 8px;
        align-items: flex-start;
        snl-button{
          width: 100%;
          .snl-button{
            width: 100%;
          }
        }
      }
    }
  }
  .BtnLft{
    display: flex;
    align-items: center;
    gap: 16px;
    &.BottomBarSkeltn{
      .btn-skltn{
        height: var(--spacing-48);
        border-radius: var(--spacing-4);
        width: 140px;
      }
      .text-skeln{
        min-width: 300px;
      }
    }
  }
  .share-icn{
    svg{
      path{
        stroke:var(--primary-disabled);
      }
    }
  }

  
  .ReadOrUnread{
    snl-button{
      .snl-button{
        padding: 0 !important;
        border: none;
        font-weight: 700;
        &:disabled{
          border: none;
        }
        &:hover{
          background: transparent;
        }
      } 

    }
  }
}


.BottomStickyBar{
  position: sticky;
  width: 100%;
  height: 80px;
  @media screen and (max-width: 992px) { 
    height: 107px;   
    justify-content: center;
    z-index: 9999;
    display: none;
    &.BottomStickyBarM{
      height: auto; 
      padding: 16px;
      &.MobileButtons-mngr{ 
        .ButtonSeparator{
          justify-content: space-between;
          gap: 16px;
          .StoltBtnIconLarge{
            width: 100%;
          }
        }
      }
    }
  }
  .MobileBtnSlcter{
    @media screen and (max-width: 992px) {
      flex-direction: column;
      gap: 12px !important; 
    }
    @media screen and (max-width: 767px) {
     flex-direction: column;
     gap: 12px !important;
     .StoltBtnIconLarge{
      width: 100%;
      margin: 0;
     }
    }
    .PhoneBtnstickyIn{
      display: flex;
      align-items: center;
      gap: 24px;
      .shareBooking{
        width: 50%;
      }
      .__Mspacebtm{
        margin: 0;
      }
    }
  }  
  background: #FFFFFF;
  box-shadow: 0px -5px 15px rgba(30, 33, 46, 0.06);
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 32px;
  z-index: 99;
  .BottomStickyBarButton{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .AddNewUserSkltn{
      width: 120px;
      height: 24px;
      border-radius: 4px;
    }
    .SeparatorMain{
      display: flex;
      align-items: center;
    }
    .ReadUnread{
      @media screen and (min-width: 768px) and (max-width: 992px) {
        width: 100%;
        border: 1px solid var(--front-color-defolt06);
        color: var(--text-color-defolt);
        border-radius: 4px; 
      }
      &.__Mspacebtm{
        border: none;
        @media screen and (max-width: 767px) {
          margin-bottom: 8px;
          button{
            height: auto;
            min-height: auto; 
          } 
        }
      }
      button{
        padding: 0 0 0 10px; 
        @media screen and (max-width: 767px) {
          padding: 0; 
          &.btn-redUnred{
            padding: 0;
            height: auto;
            min-height: auto;
            margin-bottom: 8px; 
          }
        }
        background:none;
        color: var(--text-color-defolt);
        border: none;
        font-weight: 700;
        min-width: auto;
        margin: 0;
        &:disabled{
          color: var(--front-color-defolt06);
          opacity: 1;
        }
      }
    }
    @media screen and (max-width: 992px) { 
      &.SingleButton{
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        .ButtonSeparator{
            width: 100%;
            .StoltBtnIconLarge{
              margin: 0;
              width: 100%;
            }
          }
      }
    }
    @media screen and (max-width: 992px) { 
      flex-direction: column-reverse;
      align-items: flex-start;
      &.tab-100{
        width: 100%;
        .ButtonSeparator{
          width: 100%; 
          button{
            width: 100%; 
          }
        } 
      }
    }
    .ButtonSeparator{
      display: flex;
      @media screen and (max-width: 992px) {
         width: 100%; 
         align-items: flex-start;
        .shareBooking{
          min-width: 163px; 
          margin-right: 0;
        }
      } 
    }
    .StoltBtnIconLarge{
      margin-right: 12px; 
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 100%;
      }
    }
    .ItemsSelected{ 
    margin-left: 4px; 
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px; 
    color: var(--theme-text-color-04);
    &.breaklimit{
      @media screen and (min-width: 1100px) { 
        width: 400px;
        &.breaklimit2{
          width: 350px;
          @media screen and (min-width: 1420px) { 
            width: 560px !important;
          }
        }
      }

      @media screen and (min-width: 1500px) { 
        width: 700px;
      }
    } 
    

    @media screen and (max-width: 992px) { 
      margin: 0 0 8px 0; 
    }

    }
  }
  &.IpadProBtnBottm{
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      height: auto;
      padding: 16px 32px;
      .BottomStickyBarButton{
        flex-direction: column-reverse;
        align-items: flex-start;
        .SeparatorMain{
          width: 100%;
          .ButtonSeparator{
            width: 60%;
            &.w-100D{
              width: 100%; 
            }
          }
        }
      }
    }
  }
}
.sharewhite{
  svg{
    path{stroke: #fff;}
    }
}
/* Bottom Sticky Bar Style End*/

 

.inProgressMap{
  .agm-map-container-inner{
    &.sebm-google-map-container-inner{
      border-radius: 8px !important;
      @media screen and (max-width: 992px) { 
        border-radius: 0 !important;
      }
    } 
  }
}
.mobileFullMap{
  @media screen and (max-width: 992px) {
     .map-container{ top: 0;}
  }
} 

.side-nav-bar{
  &.StoltSideBarMini{
    .sec2{
     width: 72px !important;
    }
    .side-nav-bar-logo{
      .SideBarToggler{
        svg{
          transform: rotate(180deg);
          
        }
       
      }
    }
    .SideBarToggler{ 
      span{   
          svg{ 
              transform: rotate(180deg);
          }
  
      }
    }
    .side-nav-bar-content{
      ul{
          li{
            justify-content: center !important;
            padding-left: 13px !important;
              span{
                justify-content: center !important;
                width: 40px;
                height: 40px; 
                .NotificationsBadge{ 
                  width: 18px !important;
                  height: 18px !important;
                  font-size: 11px !important; 
                  right: 5px !important; 
                  top: 3px !important;
                  border: 1.5px solid #fff;
                }
                &::before{
                  margin-bottom: 0px !important;
                }
                svg{
                  margin: 0px !important;
                }
                .NameLeters{
                  left: 0 !important;
                }
                  p{
                      display: none !important;
                  }
              }
              .CountBadge, .CountBadgeSkltn{
                display: none;
              }
          }
          &.sec2{
            @media screen and (min-width: 1430px) {
              width: 72px !important; 
            }
          } 
      } 
    }
  }
   
    .side-nav-bar-content{
      .ng-scroll-content { 
          height: 100% !important;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between; 
      }
      ul{
        &.sec2{
          @media screen and (max-width: 992px) {
            opacity:0; 
          }
  
        }
      }
    }
    @media screen and (max-width: 992px) { 
    &.OpenedMobileBar{
        .side-nav-bar-content{
           ul{
            &.sec2{
                opacity:1; 
                width: 280px !important;    
            }
           }
       }
    }
  }
}
.StoltBGDefault{
  padding-left: 216px;
  position: relative;
  @media screen and (max-width: 992px) { 
    padding: 0px;
  }
}
 


.MapModelFullCard{ 
  .agm-info-window-content{
    ng-select{
      margin: 16px 0; 
      .ng-select-container {
        padding: 0px 12px !important;
      } 
    }
  } 
  .tp_space{
    margin-top: 16px !important;
    @media screen and (max-width: 992px) {
       margin-right: 20px;
    }
  }
  .gm-ui-hover-effect{
    top: 0 !important;
    right: 0 !important;
  } 
  .gm-style-iw{
    &.gm-style-iw-c{
     padding: 0 16px !important;  
     @media screen and (max-width: 992px) { 
      width: 635px;
     }
     @media screen and (max-width: 767px) { 
      width: 300px;
     }
     min-height: 495px; 
     max-width: inherit !important;
     max-height: inherit !important; 
     box-shadow: 0px 15px 25px rgba(30, 33, 46, 0.04);
     overflow: inherit !important;  
    border: 1px solid var(--front-color-defolt07); 
    @include brdr_radius();
     &:before{
      position: absolute;
      content: ""; 
     /* background-image: url(/assets/img/circle-arround.svg);*/ 
      bottom: 0; 
      width: 95px;
      height: 95px; 
      left: 50%;
      transform: translate(-50%);
      margin-bottom: -70px;
      z-index: -9;
      border: 1px solid #fff;
      @include brdr_radius(50%);
      opacity: 0.3;
      animation: pulse 2s infinite;
      &:hover{
        animation: none;
      }
      @-webkit-keyframes pulse {
        0% {
        -webkit-box-shadow: 0 0 0 0 #fff;
        }
        70% {
          -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
      @keyframes pulse {
        0% {
        -moz-box-shadow: 0 0 0 0 #fff;
        box-shadow: 0 0 0 0 #fff;
        }
        70% {
          -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }
        100% {
          -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
    }
    &:after{
      position: absolute;
      content: ""; 
     /* background-image: url(/assets/img/circle-arround.svg);*/ 
      bottom: 0; 
      width: 47px;
      height: 47px; 
      left: 50%;
      transform: translate(-50%);
      margin-bottom: -47px;
      z-index: -9;
      border: 1px solid #fff;
      @include brdr_radius(50%);
      animation: pulse 2s infinite;
      &:hover{
        animation: none;
      }
      @-webkit-keyframes pulse {
        0% {
        -webkit-box-shadow: 0 0 0 0 #fff;
        }
        70% {
          -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
      @keyframes pulse {
        0% {
        -moz-box-shadow: 0 0 0 0 #fff;
        box-shadow: 0 0 0 0 #fff;
        }
        70% {
          -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }
        100% {
          -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
    }   
     .gm-style-iw-d{
      padding: 0px; 
      overflow: hidden !important;
      max-height: initial !important;

    }
    }
    .gm-ui-hover-effect{
      display: none !important;
    }
  }
  .agm-info-windowBX{
    .HeaderInfoWindow{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .IdTxt{  
      font-weight: normal;
      font-size: 16px;
      line-height: 19px; 
      color: var(--theme-text-color-02);
      } 
    }
    .InfoWindowTitle{ 
      font-family: var(--font-family-01); 
      font-weight: bold;
      font-size: 26px;
      line-height: 24px; 
      color: var(--theme-text-color-01);
      margin: 4px 0px 0px 0px;
    }
    .FeaturedImgBx{
      width: 100%;
      height: 148px; 
      background: #C4C4C4;
      border-radius: 6px;
      overflow: hidden;
      margin: 20px 0;
    }
    .ContentWindowBX{
      margin-top: 20px;
      ul{
        padding: 0px;
        margin: 0 0 20px 0 !important;
        &:last-child{
          margin-bottom: 40px !important;
        }
        list-style: none;
        li{
          margin-bottom: 8px;
          display: flex; 
          flex-direction: column;
          .cargo-delay{
            font-size: 12px;
            line-height: 14px;
          }
          &.CargoesListInner{
            flex-direction: column;
            .DetailsVlue{
              padding-left: 0;
              justify-content: space-between;
              display: flex;
              align-items: center;
            }

          }
          span{
            font-weight: normal;
            font-size: 16px;
            line-height: 21px; 
          }
          .LeftNameTxt{ 
            color: var(--theme-text-color-02);
            min-width: 100px;
            
          }
          .DetailsVlue{   
            font-weight: bold;
            font-size: 16px;
            line-height: 19px; 
            color: var(--theme-text-color-01);
          }
        }
        &.CargoesList{
          margin: 32px 0 !important;
          li{
            margin-bottom: 0;
          }
        }
      }
    }
    .viewBookingDetail{
      margin-bottom: 32px;
      .StoltBtnIconLarge{
        width: 100%;
        a{ 
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px; 
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; 
        color: #FFFFFF;
        width: 100%;
        }
      }
    }
    .BottomBarButton{
      display: flex;
      flex-direction: row;
      padding: 0 50px;
      justify-content: space-between;
      .StoltBtnIconLarge{
        min-width: auto;
        margin-right: 0;
        margin-bottom: 24px; 
        font-size: 16px;
        padding: 0; 
        min-height: auto;
         
      }
    }
  }
  
  &.MapMdlShareDwnd{
    @media screen and (max-width: 992px) { 
      .gm-style-iw-c{
        max-height: 400px !important;
        overflow-y: auto !important; 
        overflow-x: hidden !important;
      }
     }
  }
}
 


.IconColor{
  rect, line, path{
    stroke:var(--text-color-defolt);
  }
}
.ShareIconColor{
  path{
    fill:var(--text-color-defolt);
  } 
}


/* Front UI CSS Start Here! */
.LargeWindowWidth{
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.CommonContentSec{
  padding: 0px 80px; 
  @media screen and (max-width: 992px) {
    padding: 0px 16px;
  }
}
.GreyBG{
  background: var(--Bg-color-defolt04);
}
 
 .CommonHeroArea{
     background-color: var(--front-color-defolt);
     padding: 0px 80px; 
     @media screen and (max-width: 992px) { 
      padding: 0px 16px; 
     }
     .heading01{ 
        font-size: 72px;
        line-height: 90px; 
        letter-spacing: -0.008em; 
        color: #FFFFFF;
        padding-top: 100px;
        @media screen and (max-width: 992px) { 
            font-size: 44px;
            line-height: 50px;
            padding-top: 132px;
         
        }
     } 
 }
 /* 
 .CornerPlusEffect{
   position: relative;
   &::before, &::after{
    position: absolute;
    content: "";
    background-image: var(--pluscorners); 
    width: 48px;
    height: 48px;
    background-size: cover;
    right: -24px;
    top: -24px;
    @media screen and (max-width: 992px) { 
      right: 10px;
      background-image: var(--cornerplusmobile);  
    }
   }
   &::after{
    right: inherit;
    top: inherit;
    bottom: -24px;
    left: -24px;
    @media screen and (max-width: 992px) { 
      left: 0px;
    }
   }
 }*/
/* Front UI CSS End Here! */


/* Close Side-bar Lsuout Style */
.SideSpaceRemover{
  .StoltBGDefault{
    padding-left: 77px;
    .StoltCommonContentArea{
      .booking-heading{
        @media screen and (max-width: 1024px) {
          .StatusBarArea{
            span{
              margin-right: 40px !important; 
              span{
                margin-right: 0 !important;
              }
              &:last-child{
                margin-right: 0 !important;
              }
            }
          }
        }
      }
      .tab-slider-holder{
        .tab-slider--container{
          .tab-slider--body{
            .vessel-sec{
              .vessel-inforamtion-sec{
                @media screen and (max-width: 1024px) {
                  &.QtyArea{
                    span{
                      margin-right: 24px !important;
                      &:last-child{
                        margin-right: 0 !important;
                      }
                    }
                  }
                }
              }
            }
            .CustomAcordion{ 
              .TopToalArae{
                ul {
                  li{
                    &:nth-child(1) { 
                      width: 300px !important;
                      @media screen and (min-width: 1350px) {
                        width: 25% !important;
                      }
                    }
                    &:nth-child(2) { 
                      width: 330px !important;
                      @media screen and (min-device-width: 1300px) and (max-device-width: 1450px) { 
                        width: 330px !important;
                      }
                      @media screen and (min-width: 1350px) {
                        width: 25% !important;
                      }
                    }
                    &:nth-child(3) { 
                      width: 250px !important;
                      @media screen and (min-width: 1350px) {
                        width:25% !important;
                      }
                    }
                  }
                }     
              }
                .CustmTableStructure{
                  ul {
                    li{
                      &:nth-child(1) { 
                        width: 300px !important;
                        @media screen and (min-width: 1350px) {
                          width: 25% !important;
                        }
                      }
                      &:nth-child(2) { 
                        width: 330px !important;
                        @media screen and (min-device-width: 1300px) and (max-device-width: 1450px) { 
                          width: 330px !important;
                        }
                        @media screen and (min-width: 1350px) {
                          width: 25% !important;
                        }
                      }
                      &:nth-child(3) { 
                        width: 250px !important;
                        @media screen and (min-width: 1350px) {
                          width:25% !important;
                        }
                      }
                    }
                  }
                } 
            }
            &.VesselTabHld{
              .CustmTableStructure{
                ul{
                  li{
                    &:nth-child(2) {
                      @media screen and (min-width: 1200px) {
                         min-width: 400px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

} 
/* Close Side-bar Lsuout Style End */
 

/* Full View Model Customization */
   .FullModelSpaceing{
    padding: 32px; 
    @media screen and (max-width: 992px) {
      &.VesselMapModelHld{
        padding: 0;
        .Model-radius{
          border-radius: 0 !important;
        }
        app-agm-map{
          agm-map{
            height: 90vh !important;
          }
        }
      }
    }
    .modal-body{
      padding-bottom: 0; 
    } 
    &.ModelMapUpdated{
      .modal-title{ 
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px; 
        color: #000000;
        padding-left: 24px;
        font-family: var(--font-family-01);
      }
      .modal-body{
        padding: 0px 24px;

      }

    }
  } 
  .btn-close{
    &:focus { 
      box-shadow: none !important; 
  }
  }
 /* Full View Model Customization End */


  /* Map Model Customization */
  .MapmodelSnl{
    .modal{
      &.modal__wrapper-large{
        width: calc(100% - 32px) !important;
        height: calc(100% - 32px) !important;
        border-radius: 4px;
        z-index: 99999;
        @media screen and (max-width: 992px) {
          width: calc(100% - 0px) !important;
          height: calc(100% - 0px) !important;
          border-radius: 0;
        }
        .modal__header{
          padding: 0 24px !important;
          @media screen and (max-width: 992px) {
            margin-bottom: 0 !important;
          }
          .modal__header-icon{ 
            justify-content: flex-end;
            align-items: center; 
          }
        }
        .g-mapHeight{
          .map-container {
            height: calc(100vh - 112px) !important;
          }
        }
      }
    }
    .wrapper{
      z-index: 9999999 !important;
    }
    .HighlightsBox{ 
      position: absolute;
      width: 202px;
      height: 227px; 
      bottom: 34px;
      left: 34px; 
      background: #2B2B2B;
      border-radius: 4px;
      padding: 16px 0;
      .OptionsHld{ 
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #FFFFFF;
        padding: 0px 16px;
        margin-bottom: 8px;
        &:last-child{
          margin-bottom: 0;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          .Pills-loard{
            margin-right: 8px;
          }
        }
      }
    }
  }
 



  /* ETA Tooltip Start */
  @media screen and (max-width: 992px) { 
    app-cargo-eta-info{
    z-index: 99999 !important;
    right: inherit !important;
    left: 0; 
    }
    .infott-zindex{
      z-index: 9999;
    }
  }
  .sviInfospace{
    margin-left: 4px;
    position: relative;
    top: -1px;
    cursor: pointer;
  }
  .position_initial{
    position: initial !important; 
  }
/* ETA Tooltip End */

.tooltip-content{
  .border-dash-b{
    cursor: default;
  }
}

 .modal-header{
    .modal-title{ 
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 24px 15px 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      margin: 0;
      color: var(--theme-text-color-01);
      width: 100%;
      .btn-close{
        font-size: 15px;
      }
    }
 }
 .Model-radius{ 
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 4px !important;
}



.modal-backdrop{
  &.fade{
    &.show{
      z-index: 9999;
    }
  } 
}

.ComingSoonModel{ 
  .btn-close{
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 99;
    font-size: 15px;
  }
  .modal-body{
    padding: 32px;
    .heading01{
      font-size: 34px;
      margin-bottom: 10px;
    }
    .CommonPara{
      line-height: 22px;
    }
  } 
}
.modal{
  &.ComingSoonModel{ 
    z-index: 99999;
  }
}

.BodySafariModelInner{
  padding: 32px;
  text-align: center; 
  .error-icon{ 
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 80px;
    }
  }
  .heading01{
    font-size: 34px;
    margin-bottom: 10px;  
  }
  .CommonPara{
    line-height: 22px;
  }
}


.StoltSideBarMini{ 
  .sidebar-user{ 
      svg{
          width: 40px !important;
          height: 40px !important; 
          padding: 8px !important;
      }
      p{
        display: none;
      }
      small{
        display: none;
      }
  } 
  .icon-skelton{
    width: 40px !important;
    height: 47px !important; 
}
}

.gm-style{
  div{
    div{
      div[aria-hidden="true"]{
        margin-top: 45px;
        }
    }
  }
}


/** Q88 Page Css Start **/
.Q88-infodata{
  span{
    display: block;
    border: 1px solid var(--front-color-defolt05);
    border-radius: 3px;
    &.width-scroll{
      @media screen and (max-width: 992px) {
      width: 1150px;
      }
    }
    &.SomethingRequiredMsg{
      border: none;
      width: 100% !important;
    }
  }
 table{
    margin-bottom: 8px;  
   tr{
    border-bottom: 1px solid #E9EEF2;
   .GridHeader{
    background: #EDF1F9;
    td{
      border: none;
      height: 48px;
      font-style: normal;
      font-weight: 400; 
      line-height: 32px;
      text-transform: uppercase;
      color: #576375;

    }
   }
   td{
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 64px;
    vertical-align: middle;
    color: var(--theme-text-color-01);
    position: relative;
    background: transparent; 
    text-align: left !important; 
    padding: 0;
    word-break: break-word;
    &:first-child{
      min-width: 80px;
    }
    b{
      padding-left: 20px;
    }
    a{
      color: #007dba;
    }
    table{
      border: none;
      border-radius: 0;
      margin: 0;
      td{
        padding: 10px 16px;
      }
      
    }
   }
  }
 }
 td[rowspan]{
  border-right: 1px solid #E9EEF2;
}
}

/** Q88 Page Css End **/



.VarianceModel{
  .modal-content{
    width: 440px;
    &.DownloadBookingInfoMdl{
      width: 440px;
      .modal-body{
        display: flex;
        align-items: flex-start;
        column-gap: 100px;
        @media screen and (max-width: 767px) {
          column-gap: 20px;
        }
      }
    }
    border-radius: 8px;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    &.Xls__content{ 
      .modal-footer{
        @media screen and (max-width: 410px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 16px;
          .StoltBtnIconLarge {
            width: 100%;
            margin: 0; 
          }
        }
      }
    }
  }
  .modal-header{
    border: none;
    padding:24px;
    @media screen and (max-width: 992px) {
      padding:24px 16px;
    }
    .modal-title{
      padding: 0;
      font-family: var(--font-family-01); 
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 40px; 
      color: var(--theme-text-color-01);
    }
    .btn-close{
      font-size: 14px;
    }
  }
  .modal-body{
    padding: 0 24px;
    @media screen and (max-width: 992px) {
      padding: 0 16px; 
    }
    .heading-opt{
      display: flex;
      margin-bottom: 16px;
      .titleOpt2{
        margin: 2px 16px 0 0 !important;
      }
      ul{
        display: flex; 
        column-gap: 16px;
      }
    }
    .InfoMesaage{
      margin: 0;
      color: var(--theme-text-color-01);
    }
    &.delete__List{
      padding-top: 24px;
    }
    ul{
      &.AdditionalList{
        margin-top: 24px !important;
        &.mt-0{
          margin-top: 0 !important;
        }
      }
      li{
        margin-bottom: 8px;
        &.CustomMview{
          padding: 12px 0;
          margin: 0;
          span{
            font-size: 16px;
            line-height: 24px;
          }
          .RadioLabel{
            padding-left: 35px;
            @media screen and (max-width: 992px) {
              display: flex;
            }
          }
        }
        &.margin__btm{
          margin-bottom: 16px;
        }
        p{
          &.titleOpt{ 
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: var(--theme-text-color-01);
            margin-bottom: 16px;
            &.titleOpt3{
              margin: 8px 0;
            }
          }
        } 
      }
    }
    .containerCheckbx{
      top: 2px !important;
    }
    span{
    font-family: var(--font-family-03);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px; 
    color: var(--theme-text-color-01);
    &.RadioLabel{
      padding-left: 28px;
    }
    }
  }
  .modal-footer{
    padding: 32px 24px;
    justify-content: flex-start;
    border: none;
    flex-wrap: inherit;
    gap: 16px;
    @media screen and (max-width: 992px) {
      padding:16px; 
    }
    &.mdl__ftr{
      padding-top: 16px;
    }
    .StoltBtnIconLarge{
      width: 100%;
      margin:0; 
      @media screen and (max-width: 992px) {
        width: 100% !important;
        &.w-auto{
          width: auto !important;
        }
      }
    }
  }
}

 
.mt-6{
  margin-top: 6px;
}
.VesselInfoWindowModelCls{
  .modal-dialog{
    max-width: 794px; 
    &.mt-15{
      margin-top: 15px;
    }
    @media screen and (max-width: 767px) {
      margin: 0;
      max-width: 100%; 
    }
    .modal-content{
      border-radius: 8px;
      @media screen and (max-width: 767px) {
        border-radius: 0; 
        border: none;
      }
      .modal-header{
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: 16px 20px;

        @media screen and (max-width: 767px) {
          padding: 16px;
          position: sticky;
          top: 0;
          box-shadow: 0 0 20px 0 rgb(225 226 231);
          background: #fff;
          z-index: 99;
          &.box-shadow-hide{
            box-shadow: none;
          }
        }
        border: none;
        .model-closer{
          background: none;
          border: none;
          position: absolute;
          right: 16px;
          top: 40px;
          padding: 0;
        }
        .modal-header-inner{ 
          span{ 
            font-style: normal; 
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--theme-text-color-02);  
            &.VoyageNumber{
              color: var(--theme-text-color-01);
              font-weight: 500;  
            }

          } 
          .heading05{ 
            font-family: var(--font-family-01);
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 24px; 
            color: var(--theme-text-color-01);
            margin: 0;
          }
           
        }
      }
    }
    .ButtonHolder{
      display: flex;
      margin-top: 16px;
      a{
        text-decoration: none;
        &:hover{
          text-decoration: none;
        }
      }
      @media screen and (max-width: 767px) {
       flex-direction: column;
       width: 100%;
       align-items: center;
       
      }
      .StoltBtnIconLarge{ 
        padding: 0 20px;
        min-width: 240px; 
        margin-right: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        @media screen and (max-width: 767px) {
          &.btn-wdt-100{
            width: 100%; 
            margin: 0 0 16px 0; 

          }
        }
      }
      .btnGrp{
        display: flex;
        .StoltBtnIconLarge{
          min-width: 160px;
          @media screen and (max-width: 767px) {
            min-width: 100%;
            &:first-child{
              margin-right: 0;
            }
          }
          svg-icon{
            display: flex;
            margin-right: 8px;
          }
        }
        @media screen and (max-width: 767px) {
          justify-content: center;
          padding: 0 86px;
          width: 100%;
          flex-direction: row-reverse;
          &.btnGrp2{
            padding: 0 72px;
          }
        }
        @media screen and (min-width: 370px) and (max-width: 450px) { 
          padding: 0 100px;  
        }
      }
    }
    .modal-body{
      padding: 0 32px 20px;
      @media screen and (max-width: 767px) {
        overflow: hidden;
        padding: 0 16px 32px;
        &.pb-m-0{
          padding-bottom: 0;
        }
      }
      .modal-body-info{
        display: flex;
        border-top: 1px solid #e6e6e6;
        padding-top: 10px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
          padding-top: 24px; 
        }
        .ColumBodyinner{ 
          width: 50%; 
          ng-scrollbar{
            height: 200px; 
            --scrollbar-thumb-color:#CBD4E1; 
            --scrollbar-track-color:var(--Bg-color-defolt05); 
            .ng-scrollbar-wrapper[verticalHovered=true]{ 
              cursor: pointer;
          }
            &:hover{  
              @media screen and (min-width: 992px) {
                --scrollbar-size: 8px;
              } 
              --scrollbar-thumb-color: #b2bac7;
              --scrollbar-track-color: #e4e5e7; 
          }
            .scrollbar-holder{
              padding-right: 24px;
              .notifymessage{ 
                font-style: italic;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px; 
                color: var(--theme-text-color-02);
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;
              }
            }
          }
          span{
            &.LogTxt{  
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 28px; 
              color: var(--theme-text-color-01); 
              margin-top: 10px;
              display: flex;
              flex-direction: column;
            }
          }
          &.ColumBodyinnerLeft{
            padding: 14px 4px 0 0;
            width: 290px;
            @media screen and (max-width: 767px) {
              width: 100%;
              padding: 0;
            }
          }
          &.ColumBodyinnerRight{
            padding: 0 0 0 30px;
            width: 60%;  
            @media screen and (max-width: 767px) {
              width: 100%;
              padding: 0 0 0 0;
              margin-top: 30px;
              border: none; 
              ng-scrollbar{
                &.maps-ngcrollbar{
                  height: 180px;
                }
              }
            }
          }
          .ColuminnerInfo{ 
            &.NameDataPrt{
              span{
                  color: var(--theme-text-color-02);
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  margin: 0;
                &.d-flex{
                    flex-direction: column;
                    margin-bottom: 24px;
                    @media screen and (max-width: 767px) {
                      margin-bottom: 30px; 
                      &:last-child{
                        margin-bottom: 0;
                      }
                    }
                } 
                &.boldlabel{
                  color: var(--theme-text-color-01);
                  font-weight: 700;
                  margin-top: 2px;
                }
              }
            }
            &:last-child{
              margin-bottom: 0;
            }
            span{ 
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;  
              margin: 0 0 8px 0;
              &:last-child{
                 margin-bottom: 0;
              }
              span{ 
                margin: 0 0 0 4px;
              }
            }
          }
           
        }
        .CargosInfo{
            display: flex;
            justify-content: space-between; 
            margin: 8px 0;
            span{
              margin-bottom: 4px !important;
              color: var(--theme-text-color-02);
            }

        }
        .ChangeETAHld{ 
          .TitleEta{
            color: var(--theme-text-color-02);
          }
        }
        .innerchangeETA{
          display: flex;
          flex-direction: column;
          span{  
            margin: 0 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.spcebtm{
              margin-bottom: 16px !important;
              flex-wrap: wrap;
            }
            &.txt-inside{
              color: var(--theme-text-color-02); 
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
            }
             
              
            .cargo-delay{
            font-size: 12px;
            line-height: 14px;
            font-style: normal; 
            }
          }
        }
        .InnerTxtCargo{
          display: flex;
          justify-content: space-between;
          span{
            color: var(--theme-text-color-02); 
            margin-bottom: 0 !important;
            &.CargoName{
              font-weight: 500;
              margin-bottom: 4px !important;
              color: var(--theme-text-color-01); 
            } 
          }
        }
        .SectionInfoBdr{
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #e6e6e6;
          padding: 16px 0 12px 0;
          &:last-child{
            border-bottom:none;
          }
          .DateTxt{  
          display: flex;
          align-items: center;
          justify-content: space-between; 
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;  
          color: var(--theme-text-color-01); 
          em{
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;   
            text-align: right;
            color: var(--theme-text-color-02); 
          }
          }
          small{  
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;  
            color: var(--theme-text-color-02); 
            max-width: 190px;
            margin-top: 8px;
          }
          .DiscTxt{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px; 
            color: var(--theme-text-color-01); 
            margin-top: 8px;
          }
        }
      }
    }
  } 

}

 

app-sorting{
  &.CompletedShort{
    .sorting-detail{
      left: inherit;
      right: 0;
    }
  }
  &.SusMdlSrt{
    .sorting-detail{
      margin-top: 36px;
    }
  }
}
 .MapLoaderHld{
    height: 82vh;
    @media screen and (max-width: 1400px) {
      height: 75vh; 
    }
    @media screen and (max-width: 991px) {
      height: 90vh; 
      &.MapLoaderHld-2{
        height: 100vh; 
        position: relative;
        margin-top: 24px;
      }
    }
    @media screen and (min-width: 1800px) {
      height: 85vh; 
    }
  .MapLoaderHldInner{
    position: absolute; 
    border-radius: var(--spacing-4);
    z-index: 9999;
    height: calc(100vh - 120px);
    left: var(--spacing-24);
    top: 64px;
    width: calc(100% - 54px); 
    @media screen and (min-width: 1900px) {
      height: calc(100vh - 160px); 
      top: 100px;
    }
    @media screen and (max-width: 992px) {
      height: calc(100vh - 100px); 
      top: 80px;
    }
    background-image: var(--blur-map); 
    &.blur-map-2{
      background-image: var(--blur-map-2);
      border-radius: 0;
      @media screen and (max-width: 991px) {
        height: 100vh;
      }
      &::before{
        border-radius: 0;
      }
    }
    background-repeat:no-repeat;
    background-size: cover;
    &::before{
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, .6);
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      border-radius: var(--spacing-4);
    } 
}
  span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #fff;
    z-index: 99999;
  }
 }


 .BetaFeatures{ 
  p{
      span{ 
          em{
              font-style: normal;
              font-family: var(--font-family-04);
              color: var(--text-color-defolt);
              text-decoration: underline;
          }
      }
  }
}
 
 



  

 .FeedBackTableHolder{
  ng-scrollbar{
    height: 280px;   
      @media screen and (min-height: 720px) {
       height: 400px; 
    }
    @media screen and (min-height: 900px) {
      height: 635px; 
   }
    --scrollbar-thumb-color:#CBD4E1; 
    --scrollbar-size: 6px;
    --scrollbar-track-color:#f4f4f4;
    @media screen and (max-width: 992px) {
      display: none;
    }  
    .ng-scroll-content{
      padding-right: 15px !important;
    }
  } 
}


.ng-select-multiple{ 
  .ng-select-container{
    .ng-input input{
      display: none !important;
    }
    &.ng-has-value{
      .ng-value-container{
        align-items: center;
        font-weight: 700;
      } 
    }
  }
}
 
.snl-btn-applyCncl{
  snl-button{
    margin: 0;
    width: 48%; 
    .snl-button{
      width: 100%; 
    }
  } 
}


/** Google Map Styling Start **/
 button{
  &.gm-fullscreen-control{
    display: none !important;
   }
 }
  
 .gmnoprint[role=menubar]{
  display: none !important;
 } 

 .gmnoprint {
  &.gm-bundled-control{
    &.gm-bundled-control-on-bottom{
      right: 95px !important;
    }
  }
 }
 
 .MobileMapFl{
  &.position-relativeL{
    position: relative;
    @media screen and (max-width: 992px) {
      position: inherit;
    }
  }
  @media screen and (max-width: 992px) {
    .map-container{
      position: absolute !important;
      left: 0;
      height: 100% !important;
      z-index: 9;
    }
  }
}

.map-fleetinfo{ 
  google-map{
      .map-container{
          height: 424px !important;
      }
  }
}


input[type=number] {
  -moz-appearance: textfield;
}

.cluster{
  img{
    width: 49.68px;
    height: 49.68px;
  } 
  div{
    width: 49px !important;
    line-height: 57px !important;
    span{
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color:var(--text-color-defolt);
    }
  }
}
.mapinfoHld{
  .gm-style-iw{
    &.gm-style-iw-c{
      padding: 0 !important;
      margin-top: 5px;
      box-shadow: none; 
      .gm-style-iw-d{
        overflow: hidden !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: var(--theme-text-color-01);
        padding: 2px 20px 2px 8px; 
        position: relative; 
        svg-icon{
          margin-left: 8px;
        } 
      }
    } 
  }
  .gm-ui-hover-effect{
    top:-2px !important;
  }
  .gm-style-iw-t{
    &::after{
      display: none;
    }
  }
  .brandS-label{
    color: #FFFFFF;
    svg-icon{
      margin-left: 0 !important; 
      position: relative;
      top: -1px;
      margin-right: 8px;
    }
    &:before {
      content: "";
      position: absolute;
      background:var(--theme-text-color-01);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -9;
  }
  }
}
 /*** Google Map Styling End **/


 


.MonthYearlyDiv{ 
  .scroll-host {
    margin-right: 0 !important;
  }
}

.ChartBx{ 
  text{
    font-family: var(--font-family-01) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 18px;   
  }
  tspan{
    font-family: var(--font-family-01) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 11px !important;
  }
}

 


/* NGX SLIDER CUSTOMIZATION START */ 
.Confidencedropdown{
    width: 370px; 
    background: #FFFFFF;
    border: 1px solid #E2E7EB;
    border-radius: 8px;
    position: absolute;
    z-index: 999;
    top: 52px;
    left: 0;
    height: 0; 
    opacity: 0;
  .ngx-slider-row{
    display: flex;
    flex-direction: column;
    align-items: center;
    top: -8px;
    .ConfidenceTxt{
      font-family: var(--font-family-01);
      font-style: normal;
      font-weight: 400; 
      line-height: 21px;
      color: var(--ng-select-color);
      position: relative; 
      top: -6px;
      font-size: 14px;
    }
  }

  .ngx-slider {
    margin-top: 10px !important;
    .ngx-slider-pointer{
      background-color: var(--text-color-defolt) !important;
      width: 24px !important;
      height: 24px !important;
      border-radius: 10px !important;
      outline: none;
      border: none;
      &:after { 
        top: 7px !important; 
        left: 7px !important; 
        width: 6px !important;
        height: 6px !important;
        display: none;
    }
      &.ngx-slider-active{
        &:after{
          background-color: #2b2929 !important;
        }
      }
    }
    .ngx-slider-bar { 
      top: 10px !important;
      height: 8px !important;
      background: #CBD4E1 !important;
      border-radius: 4px !important;
    }

    .ngx-slider-selection {
      background-color: var(--text-color-defolt) !important;
      outline: none !important;
  
    } 

    .ngx-slider-bubble{ 
      color: var(--text-color-defolt) !important; 
      z-index: 9;
      font-family:var(--font-family-01);
      font-style: normal;
      font-weight: 700;
      font-size: 12px !important; 
      &.ngx-slider-ceil{
        display: none;
      }
      &.ngx-slider-floor{
        display: none;
      }
      &.ngx-slider-model-high{
        margin-left: 1px;
      }
    }
  } 
    &.active{
      height: auto; 
      opacity: 1;  
      .ConfidenceBdy{ 
        height: auto; 
        .TitleConf{
          display: block;
        } 
      }
      .Confidencedropftr{
        display: flex;
      }
    }
    .ConfidenceBdy{
      padding: 12px 12px 0 12px;
      height: 0; 
      .TitleConf{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px; 
        color: var(--ng-select-color);
        display: none;
      }
      .DragSliderHld{
        margin: 34px 0 36px 0;
      }
    }
    .Confidencedropftr{ 
      align-items: center;
      justify-content: space-between;
      background: var(--Bg-color-defolt05);
      padding: 0 12px 12px 12px;
      display: none;
      button{
        width: 160px !important;
      }
    } 
}


.Filter-ngx{ 
  top: -4px;
  margin-bottom: 0px !important;
}


.ConfidenceFltrSlider{
  height: 47px; 
  border: 1px solid #E2E7EB;
  border-radius: 6px;
  padding: 13px 35px 13px 12px;
  background: var(--ng-select-background-image);
  background-repeat: no-repeat;
  background-size: 9px;
  background-position: right 12px top 21px;
  cursor: pointer;
  &.active{
    background: var(--ng-select-opened-background-image);
    background-repeat: no-repeat;
    background-size: 9px;
    background-position: right 12px top 21px;
  }
  span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px; 
    color: var(--ng-select-color);
    .valuespan{
      font-weight: 700; 
    }
  }
}
 
/* NGX SLIDER CUSTOMIZATION END */



/* Common Components Css Start Here! */
.FloatingCross{
    position: absolute;
    right: 16px;
    top: 0; 
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    .CloseCross{
      display: flex;
      justify-content: flex-end;
      width: 26px;
      height: 18px;
      background: var(--white-color);
      cursor: pointer;
    }
    svg{
      width: 14px;
      height: 16px;
      path{
        stroke: #788496;
      }
    }
}

.overFlowHidden{
  overflow: hidden;
  height: 100%;
}

.snitsfleetmultiple-map{
  .map-container{
    height: 622px !important;
    @media screen and (max-width: 1100px) {
      height: 360px !important;
    }
  }
}


/* Feedback & Welcome Model  */
.WelcomeWrapper{
  padding: 32px 24px;
  width: 792px;
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    padding: 24px 16px;
    width: 100%;
    height: 100vh;
    background: var(--white-color);
    overflow-y: auto;
    .BannerAreaHld{
      display: flex;
      flex-direction: column-reverse;
      row-gap: 16px;
    }
  }
  .WelcomeInner{  
      .BannerArea{
          height: 342.87px;
          border-radius: 4px;
          margin-bottom: 32px;
          overflow: hidden;
          @media screen and (max-height: 1430px) {
            height: 250px; 
            margin-bottom: 24px; 
          }
          @media screen and (max-width: 992px) {
            margin-bottom: 16px;
            height: 469.73px;
            img{
              height: 469.73px;
              object-fit: cover; 
            }
          }
          @media screen and (max-width: 767px) {
            .d-banner{
              display: none;
            }
          }
          @media screen and (min-width: 768px) {
            .m-banner{
              display: none;
            }
          }
      }
      .heading026{
          font-family: var(--font-family-01); 
          color: var(--theme-text-color-01);
          line-height: 24px;
          margin-bottom: 4px; 
          @media screen and (max-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 30px; 
          }
      }
      p{  
          font-family: var( --font-family-04); 
          color: var(--theme-text-color-02);
          line-height: 32px; 
          @media screen and (max-width: 992px) {
            font-size: 18px;
            line-height: 22px;
          }
      }
      .ReferenceSec{ 
          color: var(--theme-text-color-01); 
          margin: 36px 0; 
          @media screen and (min-width: 993px) and (max-width: 1430px) {
            margin: 24px 0;
          } 
          span{  
              margin-bottom: 4px; 
          } 
          p{
            color: var(--theme-text-color-01);
            @media screen and (max-width: 992px) {
              font-size: 24px;
              line-height: 28px !important;
            }
          }
      }
      .FeedBackButtonBar{
        @media screen and (max-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
  }

}

.DarkHeaderMain{ 
  .topnav{
    &.DarkHeader{
      padding: 32px 0 30px 0;
    }
  }
  .CommonHeroArea{
    height: 113px;
    &.TitleWith-header{
      @media screen and (max-width: 992px) {
        height: auto;
        padding-bottom: 50px;
        .heading01{
          padding-top: 100px;
        }
        p{
          letter-spacing: 0.2px;
          color: var(--white-color);
          margin: 12px 0 0 0;
        }
      }
      .CommonHeroContent{
        @media screen and (min-width: 993px) {
          visibility: hidden;
          opacity: 0;
          height: 113px;
        }
      }
    }
  } 
}

.Common-bg-lines{
  @media screen and (max-width: 992px) { 
      background: var(--front-primary-color) var(--snitsmobilelines) no-repeat center center / cover;  
  }
}

.BodyTimePic{ 
  .btn{ 
    &:focus{
      box-shadow: none;
      outline: none;
    }
    .bs-chevron{
      border-width: 1.5px 0px 0 1.5px;
      border-color: #008ECF;
      &:hover{
        border-color: #008ECF;
      }
    }
  }
  .bs-timepicker-field{
    pointer-events: none;
    width: 88px;
    height: 96px;
    background: #F6F8FC;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px; 
    padding-top: 20px;
    outline: none;
    color: #27364B;
    &:focus{
      background: #F6F8FC;
      color: #27364B;
    }
  }
  tr{
    td{
      &.form-group{
        position: relative;
        &::before{
          position: absolute; 
          content: "hrs";
          color: #8392A8;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin-top: -26px;
        }  
      } 
      &:nth-child(2), &:nth-child(4){
        width: 24px;
        text-align: center;
        font-size: 26px;
        color: #8392A8;
        font-weight: 700;
      }
      &:nth-child(3){
        &.form-group{
          &::before{
            content: "mins"; 
          }
        }
      }
      &:nth-child(5){
        display: none
      }
    }

  }
}

.mat-autocomplete-panel{
  &.mat-autocomplete-visible{
      background: #FFFFFF; 
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
      border-radius: 4px; 
      padding: 0; 
      .mat-option{
          border-bottom: 1px solid #F1F4F9;
          &:hover, &:focus{
            background: transparent
          }
          .mat-option-text{
            div{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
          }
      }
      .mat-checkbox-inner-container{
        margin: 3px 20px 0 0;
        .mat-checkbox-label {
          line-height: 24px;
          font-size: 16px;
          color: #27364B;
          font-weight: 400;
         }
        .mat-ripple{
          display: none;
        }
        .mat-checkbox-frame{
            width: 20px;
            height: 20px;
            background: #FFFFFF;
            border: 1px solid #CBD4E1; 
            border-radius: 4px;
        } 
      }
  }
}
 
.toggleColleps{
  .QtyBl{
    display: none !important;
  }
  .QtyCP{
    .tbd__txt{
      border: none;
      pointer-events: none
    }
  }
  
}

.tooltip-content{
  border-bottom: none !important;
}



/* ST COMMON MODEL */
.St-CommonModel{ 
  .modal-content{
    border: none;
    border-radius: 8px;
    .modal-header{
      padding: 24px;
      @media screen and (max-width: 767px) {
       padding: 24px 16px;
     }
      border:none;
      .modal-title{
       padding: 0;
       font-size: 26px;
       line-height: 40px;
       font-weight: 700;
       color: #27364B;
       font-family: var(--font-family-01); 
      }
      .CloseModel{
       padding: 0;
       border: none;
       background: none;
      }
    }
    .modal-body{
     padding: 0 24px;
     @media screen and (max-width: 767px) {
       padding: 0 16px;
     }
     .Eta-model-body{
       display: flex;
       flex-direction: column;
       gap: 16px;
       .ContentRow{
         color: #27364B;
         border-bottom: 1px solid #DBE3ED;
         padding-bottom: 16px;
         &.BookingVoyage{
           .VoyageNumber{
             color: #64748B;
           }
         }
       }
     }
    }
    .modal-footer{
     border: none;
     padding: 16px 24px;
     justify-content: flex-start;
     @media screen and (max-width: 767px) {
       padding: 16px;
       .StoltBtnIconLarge{
         width: 100% !important;
       }
     }
    }
  } 
}

.CommonModel-80{
  .modal-dialog{
    margin: 0 auto;
    height: 100%;
    .modal-content{
      max-height: calc(100% - 80px);
      @media screen and (max-width: 767px) {
        max-height: calc(100% - 40px);
        width: 100%;
        margin: 0 8px;
      }
    }
  }
}


/* Common Components Css Start Here! */

.mat-checkbox-checked{
  &.mat-accent{
    .mat-checkbox-background {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: var(--text-color-defolt);
      border: 2px solid var(--text-color-defolt);
      svg{
        width: 15px;
        top: 1px;
      }
    }
  }
  .mat-checkbox-label {
    font-weight: 700;
  }
}

.cdk-overlay-pane{
  /*width: 430px !important;
  margin-left: -97px !important;
  @media screen and (max-width: 767px) {
    width: 320px !important;
    margin-left: -24px !important;
  }*/
  
  .mat-autocomplete-panel{
    mat-option{
      background-image: var(--radiobutton-icon-circle) !important;
      background-repeat: no-repeat !important;
      background-position: center left 12px !important;
      border: none !important;
      background-size: 20px;  
      padding: 0;
      span{
        div{
          padding: 0 48px; 
        }
      }
      &:hover{
        background-color: var(--Bg-color-defolt05);
        background-image: var(--radiobutton-icon-circle-active) !important;
      }
    }
  }

  &.mat-datepicker-popup{
    @media screen and (max-width: 992px) {
      margin-left: 0px !important;
    }
    @media screen and (max-width: 767px) {  
      width: 100% !important; 
      left: 0 !important;
      padding: 0 16px;
      .mat-datepicker-content{
        width: 100%;
      }
    }
  } 
}
 
 

#mat-autocomplete-0{  
  &::-webkit-scrollbar {
      width: 4px;
      height: 8px;
      transition: all 1s ease-in-out;
  }  
  &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
  }
  &::-webkit-scrollbar-thumb {
      background: #DBE3ED;
      border: 0px none #ffffff;
      border-radius: 10px;
      &:hover, &:active{
          background: #c5c6ca;
      } 
  }
  &::-webkit-scrollbar-track {
      background: #F6F8FC;
      border: 0px none #ffffff;
      border-radius: 10px;
      &:hover, &:active{
          background: transparent;
      } 
  }
  &::-webkit-scrollbar-corner {
      background: transparent;
  } 
}
.MapViewMn{
  .map-container{
    @media screen and (min-width: 1023px) {
       height: 100vh !important;
    }
  }
}
 
.CommonPhoneContainer{
  @media screen and (max-width: 767px) {
     padding: var(--spacing-32) var(--spacing-24);
  }
}
.CommonStaticPageText{
  text-align: center;
  color: var(--font-color-primary);
  .header-h1{
    margin-bottom: var(--spacing-8);
  }
  p{
    color: var(--font-color-primary);
    margin: 0;
  }
}
.RestrictedIcon{
  .fa-eye-slash{
    &:before {
      color: var(--font-color-primary);
    }
  }
}

.DateSelectorMn{
  .container__picker-date{
    img{
      padding-right: 0 !important;
    }
  }
  .container__picker-label{
    top: -14px !important;
  }
}

.container__page-title{
  .content__body--title{
    .header-h1{
      color: var(--font-color-primary);
    }
  }
}


.CommonResultsBoxes{
  padding: 0 16px 16px 16px;
  .section-title{
    color: #64748B;
    margin-bottom: 10px !important;
    &.Stolt-Skeleton{
      color: transparent;
      min-width: 100px;
      width: 100px;
      height: 10px;
      border-radius: 20px;
    }
  }
  ul{
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    li{
      background: #fff;
      width: 160px;
      display: flex;
      min-height: 80px;
      padding: 8px 12px 8px 16px;
      border-radius: 4px;
      flex-direction: column; 
      border: 1px solid #DBE3ED;
      &.disabledBox{
        cursor: not-allowed;
        div{
          color: #B8BFCA;
          snl-checkbox{
            opacity: .5;
            pointer-events: none;
          }
        }
      }
      &.ActiveBox{
        background: #E5F4FA;
        border-color:#E5F4FA; 
        position: relative;
        overflow: hidden;
        &::before{
          position: absolute;
          content: "";
          height: 100%;
          width: 4px;
          background-color: #008ECF;
          left: 0;
          top: 0;
        }
      }
      &.Stolt-Skeleton{
        background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
        background-size: 2000px 200px !important;
        div, span{
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
        }
      }
      div{
        margin: 0;
        font-size: 26px;
        line-height: 40px;
        color: #000000;
        font-weight: 700;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 16px; 
        snl-checkbox{
          margin-top: 8px;
        }
      }
      .body-b{
        color: #64748B;
        cursor: default;
      }
    }
  }
}

#MfpEmbed_CrossButton{
  width: 15px;
  height: 20px;
  margin-top: -17px;
  cursor: pointer;
}

snl-modal{
  .wrapper{
   .modal {
    .modal__footer{
      max-height: 90px;
      display: flex;
      align-items: center;
    }
   }
  }
}

.CstmCommonAlert{ 
  &.WarnAlert{
    background-color: #FFF5D5;
    margin-bottom: 24px;
    svg-icon{
      svg{
        path{
          fill: #FFA300;
        }
      }
    }
  }
  padding: 8px 16px;
  display: flex; 
  border-radius: 4px;
  p{
    color: #27364B;
    gap: 8px;
    display: flex;
    svg-icon{
      svg{
        display: flex;
      }
    }
  }
}

.TimeZoneDrodwnMn{
  .TimeHeadingMn{
    margin-bottom: 20px !important;
  }
  ng-select{
    &._Timezone{
      width: fit-content;
      ng-dropdown-panel{
        z-index: 9999999;
        box-shadow: 0px 10px 15px rgba(15, 26, 42, 0.1);
      }
    }
  }
}

.navigation__panel-container{
  &.scroll{
    overflow-x: hidden !important;
  }
}
/* Common Components Css end! */ 

.CompanyNameCol{
  .td__wrapper{
      .data-text{
          max-width: max-content !important;
      }
  }
}

.FilterLongName{
  ng-select{
      ng-dropdown-panel{
          .ng-option{
              .OptionTxt{
                display: inline !important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 330px;
              }
          }
      }
  }
}

._assignedSurveydrp{ 
  ng-dropdown-panel{
    .ng-dropdown-panel-items{
      .ng-option{
        div{
          height: 100%;
          display: flex;
        }
      }
    }
  }
}


/** Google Map Styling Start **/
.g-mapHeight{
  .map-container{
    height: calc(100vh - 140px) !important;
    @media screen and (max-width: 992px) {
      height: calc(100vh - 80px) !important; 
    }
  }   
}

.toast:not(.show) {
  display: block !important; 
}


.SustabsYear{
  .page-tab__content{
    &.page-tab--selected{
      font-weight: 700 !important; 
    }
  }
  @media screen and (max-width: 992px) { 
    .page-tab__container--selected{
       top: 2px !important;
    }
  }
}

.snl-table-customized{
  snl-row{
    snl-td{
      &.disabled{
        opacity: 1;
        .td__wrapper{
          opacity: .5 !important;
        }
      }
    }
  }
}

/* Calendar Customization */
.fc-theme-standard .fc-scrollgrid{
  border: none !important;
}

.fc-scrollgrid-section-header{
  th{
    background-color: #F1F4F9;
    border-bottom: none !important;
    border-top: none !important;
    &.my-custom-class{
      a{
        &.fc-timeline-slot-cushion{
          &.fc-scrollgrid-sync-inner{
            &.fc-sticky{
              color: #64748B;
              font-weight: 700;
              padding-left: 28px;
              &::before{
                position: absolute;
                content: "";
                background: #CBD4E1;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                top: 12px;
                left: 12px;
              }
            }
          }
        }
      }
    }
  }
}  
.MainCkeditor{
  .ck-file-dialog-button{
    display: none !important;
  }
  .ck-editor__editable_inline{
    &.ck-focused{
      box-shadow: none !important;
    }
    ol, ul {
      padding-left: 32px !important;
  }
  }
}

.fc {
  .fc-scroller::-webkit-scrollbar {
    width: 2px; 
    height: 8px;
    background-color: #F6F8FC !important; 
  }
  .fc-scroller::-webkit-scrollbar-thumb {
    background-color: #DBE3ED;  
    border-radius: 10px;
  }
  .fc-resource-timeline-divider {
    cursor: default;
    width: 0;
  }
  .fc-datagrid-cell-cushion{
    padding: 16px;
  }
  // .fc-timeline-now-indicator-container{
  //   z-index: 0 !important; 
  // }
} 
.fc-theme-standard td, .fc-theme-standard th{
  border: 1px solid #DBE3ED;
  border-right: none;
}
.selected-resource{
  .fc-datagrid-cell-frame{
    background: #F6F8FC;
  }
}

.fc-timeline-header-row {
  &.fc-timeline-header-row-chrono{
    .fc-timeline-slot{
      border: none !important;
      &::after{
        min-height: 4px;
        top: inherit;
        left: inherit;
      }
    }
  }
  th{
    position: relative;
    &::after{
      position: absolute;
      content: "";
      background: #DBE3ED;
      height: 100%;
      width: 1px;
      right: 0;
      z-index: 9;
      bottom: 0;
      max-height: 4px;
    }
    &.fc-timeline-slot{
      border-right: none !important;
      &::after{
        min-height: 62px;
        top: 0;
        left: -1px;
      }
    }
  }
}
.fc .fc-scrollgrid-section-footer.fc-scrollgrid-section-sticky > * {
  bottom: 80px;
}
.CalendarPreviewWrp{
  .fc .fc-scrollgrid-section-footer.fc-scrollgrid-section-sticky > * {
    bottom: 0px;
  }
  full-calendar{ 
    &::after{ 
      left: 228px; 
    }
  }
}
.fc-direction-ltr {
  .fc-timeline-slot { 
    border: none;
  }
}
full-calendar{
  position: relative;
  &::after{
    position: absolute;
    content: ""; 
    height: 100%;
    width: 5px;
    left: 284px;
    box-shadow: 10px 0 5px -2px #88888842;
    z-index: 9;
  }
  .PortStayTxt{
    font-weight: 400;
  }
  tfoot{
    position: relative;
    bottom: 0;
    tr{
      th, td{
        border: none !important;
        background: transparent !important;
      }
      th{
        &:first-child{
          background-color: transparent !important;
          .fc-scroller-harness{
            .fc-scroller{
              overflow: hidden !important;
            }
          }
        }
      }
    }
  } 
} 

.TodayDateTooltip{
  box-shadow: 0px 10px 15px 0px rgba(15, 26, 42, 0.1019607843);
  padding: 8px 16px;
  max-width: 400px;
  position: absolute;
  background: #fff;
  color: #27364B;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  z-index: 999;
  border-radius: 8px;
}
 
.MainCkeditor{
  .ck-file-dialog-button{
    display: none !important;
  }
  .ck-editor__editable_inline{
    &.ck-focused{
      box-shadow: none !important;
    }
    ol, ul {
      padding-left: 32px !important;
  }
  }
}

.ck-balloon-panel{
  display: none !important;
}
.capitalize-first{
  &::first-letter {
    text-transform: capitalize;
  }
}
.ranged__selection {
  .ranged__option[aria-label="Today"], [aria-label="Yesterday"]{
    display: none !important;
    }
}
.ck-editor__editable {
  min-height: calc(100vh - 516px);
  @media screen and (max-width: 1400px) {
    min-height: calc(100vh - 360px);
  }
}
.ck-editor__editable_inline{
  padding-left: 24px !important;
}
